import { LitElement, html, css } from "../lit.js";
import clsx from "../lib/clsx.js";

import style from "./Modal.css";

customElements.define(
  "x-modal",
  class extends LitElement {
    static properties = {
      open: { type: Boolean, reflect: true },
      sliding: { type: Boolean },
      centered: { type: Boolean },
      keepOpen: { type: Boolean },
    };

    static styles = css([style]);

    constructor() {
      super();
      // this.open = false;
      this.sliding = false;
      this.centered = false;
      this.keepOpen = false;
      this.old_overflow = null;
      this.pressedKey = null;
    }

    updated = (changedProperties) => {
      if (changedProperties.has("open")) {
        if (this.open) {
          this.old_overflow = document.body.style.overflow;
          document.body.style.overflow = "hidden";
          document.addEventListener("keydown", this.handleKeydown);
        } else {
          document.body.style.overflow = "auto";
          document.removeEventListener("keydown", this.handleKeydown);
          this.dispatchEvent(new Event("closed", { bubbles: true }));
        }
      }
    };

    render = () =>
      html`<div class="${this.open ? "open" : ""}">
        <div class="backdrop" @click=${this.close} />
        <div
          class="${clsx("dialog", {
            sliding: this.sliding,
            centered: this.centered,
          })}"
        >
          <!--pre>${this.pressedKey}</pre-->
          <slot />
        </div>
      </div>`;

    handleKeydown = (e) => {
      if (e.key === "Escape") this.close(e);
      this.pressedKey = e.key;
      this.requestUpdate();
    };

    close = (e) => {
      this.open = this.keepOpen;
      e.stopPropagation();
    };
  }
);
