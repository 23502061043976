import clsx from "../lib/clsx.js";
import { execute, destroy } from "../lib/invisible-grecaptcha.js";

import { MobxLitElement, LitElement, html } from "../lit.js";

import { state } from "../state.js";

import "../widgets/Form.js";
import "../widgets/Input.js";

import { ranks, rankNames } from "./Rank.js";
import { sexInfo } from "./Sex.js";

import { httpRequest, replaceEmptyStringsByNull, trimStrings, parseDateFromString } from "../util.js";
import Schema from "../validation.js";

const sexNames = {
  MALE: "männlich",
  FEMALE: "weiblich",
};

customElements.define(
  "x-explanation",
  class extends LitElement {
    static properties = { open: { type: Boolean } };
    constructor() {
      super();
      this.open = false;
    }
    toggle = () => {
      this.open = !this.open;
    };
    createRenderRoot() {
      return this;
    }
    render = () =>
      // id=${this.id}
      html`<svg
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          @click=${this.toggle}
        >
          <path
            fill="currentColor"
            d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
          />
        </svg>
        <div style="text-align: justify; display: ${this.open ? "block" : "none"}" @click=${this.toggle}>
          ${this.getAttribute("message")}
        </div>`;
  }
);

customElements.define(
  "x-fee",
  class extends LitElement {
    static properties = { value: { type: Number } };
    createRenderRoot() {
      return this;
    }
    render = () => (this.value ? `${this.value.toFixed(2)} €` : "kostenlos");
  }
);

customElements.define(
  "x-options",
  class extends LitElement {
    static properties = { options: { type: Array } };

    get attendance() {
      if (!this.options) return false;
      const result = this.options.filter((x) => x.type === "event_attendance_option");
      if (result.length === 1) {
        result[0].selected = true;
      }
      return result;
    }
    get misc() {
      if (!this.options) return false;
      return this.options.filter((x) => x.type === "event_option");
    }

    change = (option, e) => {
      option.selected = e.target.checked;
      this.requestUpdate();
    };

    createRenderRoot() {
      return this;
    }

    render = () => html`${this.attendance.length > 1
        ? html`<div class="form-field">
            <label class="top">Teilnahme</label>
            <div class="field">
              ${this.attendance.map(
                (x) =>
                  html`<div>
                    <x-checkbox name="attendance" .value="${x}" label="${x.name}" @change=${(e) => this.change(x, e)} />
                    <x-fee value="${x.fee_djjb}" />
                  </div>`
              )}
            </div>
          </div>`
        : null}
      ${this.misc.length
        ? html`<div class="form-field">
            <label class="top">Optionen</label>
            <div class="field">
              ${this.misc.map(
                (x) =>
                  html`<div>
                    <x-checkbox name="options" .value="${x}" label="${x.name}" @change=${(e) => this.change(x, e)} />
                    <x-fee value="${x.fee_djjb}" />
                  </div>`
              )}
            </div>
          </div>`
        : null}
      <div class="form-field">
        <label>Gebühr</label>
        <div class="${clsx("field", { "total-fee": this.options.length > 1 })}">
          <div>
            <x-fee
              value="${[...this.options]
                .filter((x) => x.selected)
                .reduce((p, c) => (p = p + (c.selected && c.fee_djjb ? c.fee_djjb : 0)), 0)}"
            />
          </div>
        </div>
      </div>`;
  }
);

customElements.define(
  "x-registration",
  class extends MobxLitElement {
    constructor() {
      super();
      this.showForm = false;
    }

    abortController = new AbortController();

    createRenderRoot() {
      return this;
    }

    activate = () => {
      this.showForm = true;
      this.style.width = "100%";
      this.requestUpdate();
      this.dispatchEvent(new Event("activate", { target: this }));
    };

    abort = () => {
      this.showForm = false;
      this.style.width = null;
      this.requestUpdate();
      this.dispatchEvent(new Event("abort", { target: this }));
    };

    cleanupReCaptcha = () => {
      if (this.hideBadge) {
        destroy();
        this.hideBadge.parentNode.removeChild(this.hideBadge);
      }
    };

    submit = (e) => {
      e.preventDefault();
      [...e.target.elements].forEach((x) => (x.disabled = true));
      const values = e.target.parentNode.values;
      if (process.env.RECAPTCHA_SITEKEY) {
        this.hideBadge = document.createElement("style");
        this.hideBadge.innerText = `.grecaptcha-badge { visibility: hidden }`;
        document.head.append(this.hideBadge);
        execute(process.env.RECAPTCHA_SITEKEY, {
          locale: "de",
          position: "bottomright",
        })
          .then((token) => {
            this.addRegistration({ ...values, recaptcha_token: token });
          })
          .catch((error) => {
            console.error(error);
            this.cleanupReCaptcha();
          });
        return;
      }
      this.addRegistration(values);
    };

    addRegistration = (values) => {
      if (!values.djjb) {
        delete values.dojo;
        delete values.rank;
      }
      values.options = [...values.attendance, ...values.options];
      delete values.attendance;
      values.birthdate = parseDateFromString(values.birthdate).toISOString().substr(0, 10);

      httpRequest("/registrations", {
        method: "POST",
        body: JSON.stringify(replaceEmptyStringsByNull(trimStrings(values))),
      })
        .then((response) => response.ok && response)
        .then((data) => {
          if (!data) {
            this.failure = "Die Anmeldung konnte nicht gespeichert werden. Bereits angemeldet?";
          } else {
            console.log(data);
            this.confirmation = {
              location: data.headers.get("location"),
              email: values.email,
            };
          }
          this.cleanupReCaptcha();
          this.requestUpdate();
        })
        .catch((error) => {
          console.error(error);
          this.cleanupReCaptcha();
        });
    };

    confirmRegistration = (e) => {
      e.preventDefault();
      [...e.target.elements].forEach((x) => (x.disabled = true));
      httpRequest(this.confirmation.location, {
        method: "POST",
        body: JSON.stringify(e.target.parentNode.values),
      })
        .then((response) => response.ok && response)
        .then(() => {
          this.confirmation = null;
          this.success =
            "Vielen Dank. Die Anmeldung wurde bestätigt. An die angegebene E-Mail-Adresse wurden weitere Informationen geschickt.";
          this.requestUpdate();
        })
        .catch(console.error);
    };

    registrationSchema = () =>
      Schema.Struct({
        event_id: Schema.Number().integer().required("Veranstaltung ungültig!"),
        given_name: Schema.String().required("Vorname fehlt!"),
        name: Schema.String().required("Name fehlt!"),
        // street: Schema.String().required("Straße/Hausnummer fehlt!"),
        // additional_address: Schema.String(),
        // postal_code: Schema.Number()
        //   .integer()
        //   .min(10000, "PLZ ungültig!")
        //   .max(99999, "PLZ ungültig!")
        //   .required("PLZ fehlt!"),
        // city: Schema.String().required("Stadt fehlt!"),
        // country: Schema.String().required("Land fehlt!"),
        email: Schema.String().email("E-Mail-Adresse ungültig!").required("E-Mail-Adresse fehlt!"),
        // phone: Schema.String()
        //   .regex(/^[0-9]+$/, "Telefonnummer ungültig!")
        //   .required("Telefonnummer fehlt!"),
        birthdate: Schema.Date().max(Date(), "Geburtsdatum ungültig!").required("Geburtsdatum fehlt!"),
        sex: Schema.String().oneOf(Object.keys(sexInfo), "Geschlecht ungültig!"),
        djjb: Schema.Boolean(),
        ...(this.data.type === "meeting"
          ? {}
          : {
              dojo: Schema.Struct({
                id: Schema.Number().integer(),
                name: Schema.String(),
              }).when("djjb", {
                is: true,
                then: (s) => s.required("Dojo fehlt!"),
              }),
              rank: Schema.Struct()
                .oneOf(ranks.enums, "Graduierung ungültig!")
                .when("djjb", {
                  is: true,
                  then: (s) => s.required("Graduierung fehlt!"),
                }),
            }),
        foreign: Schema.Array(
          Schema.Struct({
            system: Schema.String(),
            rank: Schema.String(),
            dojo: Schema.String(),
            association: Schema.String(),
          })
        ).unique("Doppelte Angaben gefunden!"),
        attendance: Schema.Array(
          Schema.Struct({
            id: Schema.Number().integer().required("Ungültige Teilnahme-Option"),
            type: Schema.String()
              .oneOf(["event_attendance_option"], "Ungültige Teilnahme-Option")
              .required("Ungültige Teilnahme-Option"),
          })
        )
          .min(1, "Teilnahme-Optionen wählen!")
          .required("Teilnahme-Optionen wählen!"),
        options: Schema.Array(
          Schema.Struct({
            id: Schema.Number().integer().required("Ungültige Option"),
            type: Schema.String().oneOf(["event_option"], "Ungültige Option").required("Ungültige Teilnahme-Option"),
          })
        ),
        // covid19: Schema.Boolean().true("Der 2G+-Regel zur Bekämpfung von Covid-19 muss zugestimmt werden!"),
        terms: Schema.Boolean().true("Der Speicherung von Daten muss zugestimmt werden!"),
        media: Schema.Boolean().true("Der Verwendung von Bild- und Ton-Material muss zugestimmt werden!"),
      });

    render = () => {
      if (this.data.external) {
        return html`Die Anmeldung erfolgt nicht über dieses Portal.
        ${this.data.external_url
          ? html`<div>
              <a href="${this.data.external_url}" target="_blank" rel="noopener noreferrer">Weitere Informationen</a>
            </div>`
          : null}`;
      }

      if (this.data.type === "exam") {
        return html`<div>
          <i>
            Anmeldungen erfolgen durch den Dojoleiter und müssen
            <b>8 Wochen</b> vor der Prüfung mit der schriftlichen Ausarbeitung eingegangen sein.
          </i>
        </div>`;
      }

      let deadline = new Date(this.data.date).setHours(0, 0, 0, 0);
      if (this.data.end_of_registration) deadline = new Date(this.data.end_of_registration).setHours(23, 59, 59, 999);
      const today = new Date().setHours(0, 0, 0, 0);
      if (deadline <= today) {
        return html`Anmeldung geschlossen`;
      }

      // if (this.data.capacity && this.data.number_of_registrations >= this.data.capacity) {
      //   return html`Anmeldung geschlossen (Teilnehmerlimit erreicht)`;
      // }

      if (!this.showForm) {
        if (this.data.capacity && this.data.number_of_registrations >= this.data.capacity) {
          return html`<button @click=${this.activate}>Anmelden (Warteliste)</button>`;
        }
        return html`<button @click=${this.activate}>Anmelden</button>`;
      }
      const attendance = this.data.options.filter((x) => x.type === "event_attendance_option");
      if (this.failure) {
        return html`<div class="error-message">${this.failure}</div>`;
      }
      if (this.success) {
        return html`<div class="success-message">${this.success}</div>`;
      }
      if (this.confirmation) {
        return html`<div class="success-message">
          Vielen Dank. Die Anmeldung wurde gespeichert. Zur Bestätigung wurde ein Code an ${this.confirmation.email}
          geschickt. Einfach auf den enthaltenen Link klicken und die Anmeldung wird gültig.
          <x-form .values=${{ token: "" }} @submit=${this.confirmRegistration}>
            <div class="form-field confirmation-dialog">
              <label>Oder direkt hier eingeben:</label>
              <input type="text" name="token" placeholder="Bestätigungs-Code" />
            </div>
          </x-form>
        </div>`;
      }
      return html`<x-form
        .values=${{
          event_id: this.data.id,
          djjb: this.data.djjb_only,
          attendance: attendance.length > 1 ? [] : attendance,
          options: [],
        }}
        .validate=${(values) => this.registrationSchema().validate(values)}
        @submit=${this.submit}
        @cancel=${this.abort}
      >
        <div class="form-field">
          <label>Name</label>
          <div class="field">
            <input type="text" name="given_name" placeholder="Vorname" />
            <input type="text" name="name" placeholder="Nachname" />
          </div>
        </div>
        <!--div class="form-field">
          <label>
            Adresse<x-explanation
              message="Um den COVID-19-bedingten Auflagen zur Durchführung unserer Veranstaltungen zu entsprechen, müssen wir die Kontaktdaten der Teilnehmer bis einen Monat nach der Veranstaltung speichern."
            />
          </label>
          <div class="field">
            <input type="text" name="street" placeholder="Straße / Hausnummer" />
          </div>
        </div>
        <div class="form-field">
          <div class="field">
            <input type="text" name="additional_address" placeholder="Adresszusatz (optional)" />
          </div>
        </div>
        <div class="form-field">
          <div class="field city">
            <input type="text" name="postal_code" placeholder="PLZ" />
            <input type="text" name="city" placeholder="Stadt" />
          </div>
        </div>
        <div class="form-field">
          <div class="field">
            <input type="text" name="country" placeholder="Land" />
          </div>
        </div>
        <div class="form-field">
          <label>
            Telefonnummer<x-explanation
              message="Um den COVID-19-bedingten Auflagen zur Durchführung unserer Veranstaltungen zu entsprechen, müssen wir die Kontaktdaten der Teilnehmer bis einen Monat nach der Veranstaltung speichern."
            />
          </label>
          <div class="field">
            <input type="tel" name="phone" placeholder="" />
          </div>
        </div-->
        <div class="form-field">
          <label>
            E-Mail-Adresse<x-explanation
              message="Eine gültige E-Mail-Adresse wird benötigt, um die Anmeldung zu bestätigen. Außerdem wird darüber auf Änderungen hingewiesen und die Abmeldung ermöglicht."
            />
          </label>
          <div class="field">
            <input type="email" name="email" placeholder="" />
          </div>
        </div>
        <div class="form-field">
          <label>
            Geburtsdatum<x-explanation
              message="Bei einigen Veranstaltungen ist das Alter Teil der Zulassungsvoraussetzungen oder ermöglicht den Referenten eine zielgerichtete Vorbereitung."
            />
          </label>
          <div class="field">
            <input type="date" name="birthdate" placeholder="tt.mm.jjjj" />
          </div>
        </div>
        <div class="form-field">
          <label>Geschlecht</label>
          <div class="field">
            ${Object.entries(sexInfo).map(([k, v]) => html`<x-radio name="sex" .value="${k}" label="${v.name}" />`)}
            (freiwillige Angabe)
          </div>
        </div>
        ${this.data.type === "meeting"
          ? null
          : html`<div class="form-field">
              <label>Zugehörigkeit</label>
              <div class="field">
                <x-select name="dojo" placeholder="Verein" .options=${state.dojos} .optionToString=${(x) => x.name} />
                <x-select
                  name="rank"
                  placeholder="Graduierung"
                  .options=${ranks.enums}
                  .optionToString=${(x) => rankNames[x]}
                  .optionToCandidate=${(x) => html`<x-rank value="${x}" />`}
                />
              </div>
            </div>`}
        <x-options .options=${this.data.options} />
        <!--div class="form-field">
          <label class="top">Covid-19</label>
          <div class="field" style="display: block; text-align: justify; hyphens: auto">
            <a
              href="https://www.djjb.de/Downloads/Hygieneempfehlungen-DJJB_Stand-15.06.2020.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Hygieneempfehlungen-DJJB_Stand-15.06.2020.pdf
            </a>
            Der Vorstand des DJJB hat entschieden, dass sämtliche Lehrgänge unter Einhaltung der 2G+-Regel durchgeführt
            werden. Ein Selbsttest vor Ort wird nicht akzeptiert.
            <x-checkbox
              name="covid19"
              label="Ich habe zur Kenntnis genommen, dass ich für die Teilnahme an der Veranstaltung einen Impf- oder
            Genesenenachweis und ein negatives Testzertifikat vorlegen muss."
            />
          </div>
        </div-->
        <div class="form-field">
          <label class="top">Datenschutz</label>
          <div class="field" style="display: block; text-align: justify; hyphens: auto">
            <x-checkbox
              name="terms"
              label="Ich stimme der Speicherung meiner Daten bis zum Ende der
            Veranstaltung zu. Danach werden sie automatisch gelöscht. Ich kann
            mich jederzeit von der Veranstaltung abmelden und meine Daten sofort
            löschen lassen."
            />
            Im Rahmen der Veranstaltung können Bild-, Ton- und Filmaufnahmen von und mit den Teilnehmern gemacht werden.
            <x-checkbox
              name="media"
              label="Ich stimme zu, dass die Aufnahmen für Verbandszwecke (z.B.
            Publikationen, Homepage und Social-Media-Kanäle) veröffentlicht werden
            können."
            />
          </div>
        </div>
        <x-error-messages />
        ${process.env.RECAPTCHA_SITEKEY
          ? html` <small class="recaptcha">
              Diese Seite wird durch reCAPTCHA vor automatisierten Eingaben geschützt und es gelten die
              <a href="https://policies.google.com/privacy">Datenschutzerklärung</a>
              und
              <a href="https://policies.google.com/terms">Nutzungsbedingungen</a>
              von Google.
            </small>`
          : null}
        <div class="justify-right">
          <button type="button" name="cancel">Abbrechen</button>
          <button type="reset">Zurücksetzen</button>
          <button type="submit">Anmelden</button>
        </div>
      </x-form>`;
    };
  }
);
