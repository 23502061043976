import highcharts from "../lib/highcharts.js";
import highcharts3D from "../lib/highcharts/highcharts-3d.js";
import { LitElement, html, css } from "../lit.js";

import style from "./Chart.css";

highcharts3D(highcharts);

customElements.define(
  "x-chart",
  class extends LitElement {
    static styles = css([style]);

    firstUpdated = () => {
      this.chart = highcharts["chart"](this.shadowRoot.querySelector("div"), this.options);
    };

    render = () => html`<div />`;

    disconnectedCallback() {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
    }

    // let HighchartsWebComponent = class HighchartsWebComponent extends LitElement {
    //   constructor() {
    //     super(...arguments);
    //     /**
    //      * String for constructor method. Official constructors:
    //      *  - 'chart' for Highcharts charts
    //      *  - 'stockChart' for Highstock charts
    //      *  - 'mapChart' for Highmaps charts
    //      *  - 'ganttChart' for Gantt charts
    //      */
    //     this.constructorType = "chart";
    //     /**
    //      * Used to pass the Highcharts instance after modules are initialized.
    //      * If not set the component will try to get the Highcharts from window.
    //      */
    //     this.highcharts = highcharts;
    //     /**
    //      * This wrapper uses chart.update() method to apply new options
    //      * to the chart when changing the parent component.
    //      * This option allow to turn off the updating.
    //      */
    //     this.allowChartUpdate = true;
    //     /**
    //      * Reinitialises the chart on prop update (as oppose to chart.update())
    //      * useful in some cases but slower than a regular update.
    //      */
    //     this.immutable = false;
    //     /**
    //      * Array of update()'s function optional arguments.
    //      * Parameters should be defined in the same order like in
    //      * native Highcharts function: [redraw, oneToOne, animation].
    //      * (Here)[https://api.highcharts.com/class-reference/Highcharts.Chart#update] is a more specific description of the parameters.
    //      */
    //     this.updateArgs = [true, true, true];
    //   }
    //   firstUpdated() {
    //     this.createChart();
    //   }
    //   updated() {
    //     if (this.allowChartUpdate !== false) {
    //       if (!this.immutable && this.chart) {
    //         this.chart.update(this.options, ...(this.updateArgs || [true, true]));
    //       } else {
    //         this.createChart();
    //       }
    //     }
    //   }
    //   render() {
    //     return html` <div></div> `;
    //   }
    //   createChart() {
    //     const H = this.highcharts;
    //     const constructorType = this.constructorType;
    //     if (!H) {
    //       console.warn('The "highcharts" property was not passed.');
    //     } else if (!H[constructorType]) {
    //       console.warn('The "constructorType" property is incorrect or some ' + "required module is not imported.");
    //     } else if (!this.options) {
    //       console.warn('The "options" property was not passed.');
    //     } else {
    //       // Create a chart
    //       this.chart = H[constructorType](this.chartContainer, this.options, this.chartCreated.bind(this));
    //     }
    //   }
    //   chartCreated(chart) {
    //     let e = new CustomEvent("load", {
    //       detail: chart,
    //     });
    //     this.dispatchEvent(e);
    //   }
    //   disconnectedCallback() {
    //     if (this.chart) {
    //       this.chart.destroy();
    //       this.chart = null;
    //     }
    //   }
    // };
  }
);
