import { LitElement, html, css } from "../lit.js";

import style from "./Progress.css";

customElements.define(
  "x-progress",
  class extends LitElement {
    static properties = {
      value: { type: Number },
      total: { type: Number },
      caption: { type: String },
    };

    static styles = css([style]);

    render = () =>
      html`<div class="bar" style="width: ${Math.round((this.value * 100) / this.total)}%" />
        <div class="caption">${this.caption}</div>`;
  }
);
