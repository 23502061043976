import { LitElement, html } from "../lit.js";

export const sexInfo = {
  MALE: { name: "männlich", symbol: "♂" },
  FEMALE: { name: "weiblich", symbol: "♀" },
  INTER: { name: "divers", symbol: "⚥" }, // ☿
};

customElements.define(
  "x-sex",
  class extends LitElement {
    static properties = { value: { converter: (value) => (value === "null" ? null : value) } };
    render = () =>
      this.value ? html`<span title="${sexInfo[this.value].name}">${sexInfo[this.value].symbol}</span>` : null;
  }
);
