import { MobxLitElement, html } from "../lit.js";

import { state } from "../state.js";
import { httpRequest } from "../util.js";

import "./DJJBModal.js";

customElements.define(
  "x-event-admin-panel",
  class extends MobxLitElement {
    createRenderRoot() {
      return this;
    }

    render = () => {
      if (!state.session) return;

      if (this.data.external) return;

      if (
        !state.session.user.roles.includes("admin") &&
        !state.session.user.roles.includes("board_member") &&
        !this.data.trainers?.map((x) => x.id).includes(state.session.user.id)
      )
        return;

      const number_of_registrations = Math.min(this.data.number_of_registrations, this.data.capacity || 1e9);
      const number_of_queued_registrations = this.data.number_of_registrations - number_of_registrations;
      return html`<div class="attribute">
          <div class="label">Anmeldungen:</div>
          <div class="content">${number_of_registrations}</div>
          ${this.data.number_of_pending_confirmations
            ? html`<div class="label">zu bestätigen:</div>
                <div class="content">${this.data.number_of_pending_confirmations}</div>`
            : null}
          ${number_of_queued_registrations
            ? html`<div class="label">Warteliste:</div>
                <div class="content">${number_of_queued_registrations}</div>`
            : null}
        </div>
        <div class="actions">
          <a href="#registrations=${this.data.id}">
            <button>Liste</button>
          </a>
        </div>`;
    };
  }
);
