import { observable, decorate } from "./lib/mobx.js";

import { httpRequest } from "./util.js";

class State {
  constructor() {
    httpRequest("/state", { signal: this.abortController.signal })
      .then((response) => response.ok && response.json())
      .then((data) => {
        this.last_update = data.last_update;
      })
      .catch(console.error);
    httpRequest("/dojos", { signal: this.abortController.signal })
      .then((response) => response.ok && response.json())
      .then((data) => (this.dojos = data.filter((x) => ![4, 10, 12, 16, 23].includes(x.id))))
      .catch(console.error);
    this.session = null;
    this.checkAuth();
  }

  abortController = new AbortController();

  checkAuth = () =>
    httpRequest("/auth?rel=user", {
      signal: this.abortController.signal,
    }).then(async (response) => {
      this.session = response.ok ? await response.json() : null;
    });

  logOut = () => httpRequest("/auth", { method: "DELETE" }).then(this.checkAuth).catch(console.log);
}

decorate(State, {
  last_update: observable,
  session: observable,
  dojos: observable,
});

export const state = new State();
