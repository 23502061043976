import { TemplateResult, defaultTemplateProcessor } from "./lib/lit-html.js";

const parser = new DOMParser();
const parseHTML = (str) => parser.parseFromString(str, "text/html").body.innerHTML;

class SelfClosingTemplateResult extends TemplateResult {
  constructor(strings, values) {
    super(strings, values, "html", defaultTemplateProcessor);
  }

  iterateNodes = (node) => {
    console.log(node);
    node.childNodes.forEach((x) => this.iterateNodes(x));
  };

  getHTML = () =>
    super
      .getHTML()
      .split(/ ?\/>/)
      .map((x) => {
        let i = x.length;
        do {
          i = x.substr(0, i).lastIndexOf("<");
        } while ((x.substr(i + 1).match(/"/g) || []).length % 2);
        const tag = x.substr(i).match(/<([\w-]+)/);
        if (tag) {
          const closedTag = `<${tag[1]}></${tag[1]}>`;
          return parseHTML(closedTag) === closedTag ? `${x}></${tag[1]}>` : `${x}/>`;
        }
        return x;
      })
      .join("");
}

export const html = (strings, ...values) => new SelfClosingTemplateResult(strings, values);
export { LitElement, css } from "./lib/lit-element.js";
export { MobxLitElement } from "./lib/@adobe/lit-mobx.js";
