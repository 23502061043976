import { LitElement, html, css } from "../lit.js";

import clsx from "../lib/clsx.js";
import { uuid } from "../util.js";

import style from "./Select.css";

customElements.define(
  "x-select",
  class extends LitElement {
    static properties = {
      name: { type: String },
      value: { type: String },
      placeholder: { type: String },
      options: { type: Array },
    };

    constructor() {
      super();
      this._id = uuid();
      this.placeholder = "";
      this.showCandidates = false;
      this.preselectedOption = -1;
      this.ignoreInputEvent = false;
    }

    createRenderRoot() {
      return this;
    }

    getStringFromOption = (option) => {
      if (!option) return "";
      return this.optionToString ? this.optionToString(option) : option;
    };
    getCandidateFromOption = (option) =>
      this.optionToCandidate ? this.optionToCandidate(option) : this.getStringFromOption(option);

    render = () =>
      html`<style>
          ${css([style])}
        </style>
        <div class="${clsx({ "show-candidates": this.showCandidates })}">
          <div class="input">
            <input
              type="text"
              id="${this._id}"
              name="${this.name}"
              value="${this.getStringFromOption(this.value)}"
              placeholder="${this.placeholder}"
              autocomplete="new-password"
              @focus=${this.handleFocus}
              @blur=${this.handleBlur}
              @input=${this.handleInput}
              @keydown=${this.handleKeyDown}
            />
          </div>
          <div class="options">
            <ul>
              ${(this.options || []).map(
                (x, i) =>
                  html`<li
                    class="${clsx({ active: this.preselectedOption === i })}"
                    @click=${() => this.updateValue(x)}
                  >
                    ${this.getCandidateFromOption(x)}
                  </li>`
              )}
            </ul>
          </div>
        </div>`;

    handleFocus = () => {
      this.showCandidates = true;
      this.requestUpdate();
    };

    handleBlur = () => {
      window.setTimeout(() => {
        this.showCandidates = false;
        this.requestUpdate();
      }, 200);
    };

    handleInput = () => {
      if (!this.ignoreInputEvent) this.updateValue(null);
      this.ignoreInputEvent = false;
    };

    handleKeyDown = (e) => {
      let offset = e.key === "ArrowDown" ? 1 : e.key === "ArrowUp" ? -1 : 0;
      if (offset && this.options) {
        e.preventDefault();
        if (!this.showCandidates) {
          this.showCandidates = true;
          this.preselectedOption = this.value ? this.options.indexOf(this.value) - offset : -1;
        }
        this.preselectedOption = Math.min(Math.max(this.preselectedOption + offset, 0), this.options.length - 1);
        this.requestUpdate();
      }
      if (this.preselectedOption !== -1 && ["Enter", "ArrowRight", "Tab"].includes(e.key)) {
        if (this.showCandidates) e.preventDefault();
        this.showCandidates = false;
        this.selectOption(this.preselectedOption);
      }
      if (e.key === "Backspace") {
        e.preventDefault();
        this.preselectedOption = -1;
        this.updateValue(null);
      }
    };

    selectOption = (index) => {
      this.preselectedOption = index;
      this.updateValue(this.options[index]);
    };

    updateValue = (option) => {
      this.value = option;
      const input = this.querySelector(`[id="${this._id}"]`);
      input.value = this.getStringFromOption(option);
      this.requestUpdate();
      let event = new Event("change", { bubbles: true });
      Object.defineProperty(event, "target", { writable: false, value: this });
      input.dispatchEvent(event);
      this.ignoreInputEvent = true;
      input.dispatchEvent(new Event("input", { bubbles: true }));
    };
  }
);
