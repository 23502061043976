import clsx from "../lib/clsx.js";
import showdown from "../lib/showdown.js";
import { unsafeHTML } from "../lib/lit-html/directives/unsafe-html.js";

import { LitElement, html } from "../lit.js";

import "../widgets/Modal.js";
import "../widgets/Progress.js";

import "./Registration.js";
import "./EventAdminPanel.js";

const dayFormatter = new Intl.DateTimeFormat("de-DE", {
  weekday: "short",
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  timeZone: "Europe/Berlin",
});

const timeFormatter = new Intl.DateTimeFormat("de-DE", {
  hour: "2-digit",
  minute: "2-digit",
  timeZone: "Europe/Berlin",
});

const markdown = new showdown.Converter({ tables: true });

class EventAttribute extends LitElement {
  firstUpdated = () => {
    super.firstUpdated();
    this.classList.add("attribute");
  };
  createRenderRoot() {
    return this;
  }
}

customElements.define(
  "x-event-date",
  class extends LitElement {
    static properties = { data: { type: Object } };
    createRenderRoot() {
      return this;
    }
    render = () =>
      html`<h5>
        <small>
          <span style="white-space: nowrap"> ${dayFormatter.format(Date.parse(this.data.date))} </span>
          ${this.data.end_date
            ? html` -
                <span style="white-space: nowrap"> ${dayFormatter.format(Date.parse(this.data.end_date))} </span>`
            : null}
        </small>
      </h5>`;
  }
);
customElements.define(
  "x-event-title",
  class extends LitElement {
    createRenderRoot() {
      return this;
    }
    render = () =>
      html`<h5 class="${clsx({ "valid-as-mandatory": this.data.valid_as_mandatory })}">${this.data.title}</h5>`;
  }
);
customElements.define(
  "x-event-description",
  class extends LitElement {
    createRenderRoot() {
      return this;
    }
    render = () => html`<div>${this.data.description}</div>`;
  }
);
customElements.define(
  "x-event-organizer",
  class extends LitElement {
    createRenderRoot() {
      return this;
    }
    render = () => html`<h5><small>${this.data.organizer.name}</small></h5>`;
  }
);
customElements.define(
  "x-event-organizer-detail",
  class extends EventAttribute {
    render = () =>
      html`<div class="label">Ausrichter:</div>
        <div class="content">${this.data.organizer.name}</div>`;
  }
);
customElements.define(
  "x-event-location",
  class extends EventAttribute {
    render = () =>
      this.data.location &&
      html`<div class="label">Adresse:</div>
        <div class="content address">
          <div class="address-name">${this.data.location.name}</div>
          <div class="address-street">${this.data.location.street}</div>
          <div class="address-city">${this.data.location.postal_code} ${this.data.location.city}</div>
          <div class="address-gmaps">
            <a
              href="https://www.google.de/maps/place/${this.data.location.street},${this.data.location
                .postal_code},${this.data.location.city}"
              target="_blank"
              rel="noopener noreferrer"
              >In GoogleMaps öffnen</a
            >
          </div>
          ${this.data.location.hints ? html`<div class="address-hint">${this.data.location.hints}</div>` : null}
        </div>`;
  }
);
customElements.define(
  "x-event-city",
  class extends LitElement {
    createRenderRoot() {
      return this;
    }
    render = () => this.data.location && html`<div>${this.data.location.city}</div>`;
  }
);
customElements.define(
  "x-event-trainer-list",
  class extends EventAttribute {
    render = () =>
      this.data.type === "seminar" && this.data.all_trainers.length
        ? html`
            <div class="label">Trainer:</div>
            <div class="content">
              <ul class="comma">
                ${this.data.all_trainers.map((x) => html`<li>${x.display_name}</li>`)}
              </ul>
            </div>
          `
        : null;
  }
);
customElements.define(
  "x-event-end-of-registration",
  class extends EventAttribute {
    render = () =>
      this.data.end_of_registration &&
      html`<div class="label">Anmeldeschluss:</div>
        <div class="content">${dayFormatter.format(Date.parse(this.data.end_of_registration))}</div>`;
  }
);
customElements.define(
  "x-event-capacity",
  class extends EventAttribute {
    render = () => null;
    // this.data.capacity &&
    // html`
    //   <div class="label">Max. Teilnehmer:</div>
    //   <div class="content">${this.data.capacity}</div>
    // `;
  }
);
customElements.define(
  "x-event-capacity-bar",
  class extends LitElement {
    createRenderRoot() {
      return this;
    }
    render = () => {
      if (!this.data.capacity) {
        return null;
      }
      const n = this.data.capacity;
      const x = this.data.number_of_registrations;
      console.log(n, x, Math.floor(n * (1 - Math.exp(-x / n))));
      return html`<x-progress
        value=${Math.floor(n * (1 - Math.exp(-x / n)))}
        total=${this.data.capacity}
        caption=""
      />`;
    };
  }
);

customElements.define(
  "x-event-additional-details",
  class extends LitElement {
    createRenderRoot() {
      return this;
    }
    render = () => {
      if (this.data.additional_details) return unsafeHTML(markdown.makeHtml(this.data.additional_details));
      return null;
    };
  }
);

customElements.define(
  "x-event-schedule",
  class extends EventAttribute {
    render = () =>
      Object.entries(
        this.data.time_slots.reduce((p, c) => {
          const day = dayFormatter.format(Date.parse(c.begin));
          p[day] = [...(p[day] || []), c];
          return p;
        }, {})
      )
        .sort((a, b) => a[0] > b[0])
        .map(([day, slots]) =>
          slots
            .sort((a, b) => a.begin > b.begin || (a.begin === b.begin && a.end > b.end))
            .map(
              (x, i) =>
                html`<div class="label">${i ? "" : day}</div>
                  <div class="content">
                    <div class="time">
                      ${timeFormatter.format(Date.parse(x.begin))}
                      ${x.end && `- ${timeFormatter.format(Date.parse(x.end))}`} Uhr
                    </div>
                    <div class="details">
                      ${x.title}
                      ${x.trainers &&
                      html`<div>
                        <small>
                          <ul class="comma">
                            ${x.trainers.map((x) => html`<li>${x.display_name}</li>`)}
                          </ul>
                        </small>
                      </div>`}
                    </div>
                  </div>`
            )
        );
  }
);

customElements.define(
  "x-event-actions",
  class extends LitElement {
    constructor() {
      super();
      this.active = null;
    }
    setActive = (value) => {
      this.active = value;
      this.requestUpdate();
    };
    // view = () => html`
    //   ${!this.active || this.active === "edit"
    //     ? html`<button @click=${() => this.setActive("edit")}>
    //         Bearbeiten
    //       </button>`
    //     : null}
    createRenderRoot() {
      return this;
    }
    render = () => html`
      ${!this.active || this.active === "register"
        ? html`<x-registration
            .data=${this.data}
            @activate=${() => this.setActive("register")}
            @abort=${() => this.setActive(null)}
          />`
        : null}
    `;
  }
);

customElements.define(
  "x-event",
  class extends LitElement {
    static properties = { showDetails: { type: Boolean, reflect: true } };

    constructor() {
      super();
      this.showDetails = false;
      this.addEventListener("click", this.openDetails);
    }

    firstUpdated = () => {
      if (this.data.id === 63) {
        // this.showDetails = true;
      }
    };

    openDetails = (e) => {
      this.showDetails = true;
      // this.requestUpdate();
      // e.stopPropagation();
    };
    closeDetails = (e) => {
      // console.log("closing details");
      this.showDetails = false;
      // this.requestUpdate();
      e.stopPropagation();
    };

    createRenderRoot() {
      return this;
    }

    render = () => html`<x-event-date .data=${this.data} />
      <x-event-title .data=${this.data} />
      <x-event-organizer .data=${this.data} />
      <x-event-city .data=${this.data} />
      <x-event-description .data=${this.data} />
      <x-event-end-of-registration .data=${this.data} />
      <x-event-capacity .data=${this.data} />
      <x-event-capacity-bar .data=${this.data} />
      <x-event-location .data=${this.data} />
      <x-event-trainer-list .data=${this.data} />
      <x-event-admin-panel .data=${this.data} />
      <x-modal ?open=${this.showDetails} @closed=${this.closeDetails} centered sliding>
        <div class="event-details">
          <header>
            <h5>${this.data.title}</h5>
            <span class="close-button" @click=${this.closeDetails}>&times;</span>
          </header>
          <main>
            <div>
              ${this.data.description}${this.data.valid_as_mandatory
                ? html`<br />
                    <i> Dieser Lehrgang wird als Pflichtlehrgang vor einer Prüfung anerkannt. </i>`
                : null}
            </div>
            <p>
              <x-event-schedule .data=${this.data} />
            </p>
            <x-event-organizer-detail .data=${this.data} />
            <x-event-trainer-list .data=${this.data} />
            <x-event-location .data=${this.data} />
            <x-event-end-of-registration .data=${this.data} />
            <x-event-capacity .data=${this.data} />
            <x-event-capacity-bar .data=${this.data} />
            <x-event-additional-details .data=${this.data} />
          </main>
          <!--pre>${JSON.stringify(this.data, null, 2)}</pre-->
          <footer>
            <x-event-actions class="justify-right" .data=${this.data} />
          </footer>
        </div>
      </x-modal>`;
  }
);
