import zipcelx from "../lib/zipcelx.js";

import { LitElement, html } from "../lit.js";

import { state } from "../state.js";

import { httpRequest, range } from "../util.js";
import renderTable from "../widgets/Table.js";

import "../widgets/Form.js";
import "../widgets/Chart.js";

import "./DJJBModal.js";
import "./Sex.js";
import "./Rank.js";
import { ranks, rankNames } from "./Rank.js";

const dayFormatter = new Intl.DateTimeFormat("de-DE", {
  weekday: "short",
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  timeZone: "Europe/Berlin",
});

customElements.define(
  "x-registrations",
  class extends LitElement {
    static properties = {
      event: { type: Number },
      registrationToReject: { type: Object },
      sort: { type: Object },
      filter: { type: Object },
    };

    constructor() {
      super();
      this.registrationToReject = null;
      this.sort = [];
      this.filter = [];
      this.addEventListener("sort", this.handleSort);
      this.addEventListener("filter", this.handleFilter);
    }

    handleSort = (e) => {
      e.stopPropagation();
      const column = this.sort.filter((x) => x[0] === e.detail.column)[0];
      this.sort = (e.detail.shift ? this.sort.filter((x) => x[0] !== e.detail.column) : []).concat(
        column ? (column[1] === "asc" ? [[column[0], "desc"]] : []) : [[e.detail.column, "asc"]]
      );
    };

    handleFilter = (e) => {
      e.stopPropagation();
      this.filter = this.filter
        .filter((x) => x[0] !== e.detail.column)
        .concat(e.detail.mask ? [[e.detail.column, e.detail.mask]] : []);
    };

    createRenderRoot() {
      return this;
    }

    abortController = new AbortController();

    updateData = () =>
      httpRequest(`/events/${this.event}?rel=location;rel=registrations;rel=dojo;rel=options`, {
        signal: this.abortController.signal,
      })
        .then(async (response) => {
          if (response.ok) {
            this.data = await response.json();
            this.requestUpdate();
          }
        })
        .catch(console.error);

    confirmRegistration = (registration) =>
      httpRequest(`/registrations/${registration.id}`, {
        method: "PATCH",
        body: JSON.stringify({ confirmed: true }),
        signal: this.abortController.signal,
      })
        .then((response) => {
          if (response.ok) {
            this.updateData();
            this.registrationToReject = null;
          }
        })
        .catch(console.error);

    rejectRegistration = (e) => {
      e.preventDefault();
      [...e.target.elements].forEach((x) => (x.disabled = true));
      httpRequest(`/registrations/${this.registrationToReject.id}`, {
        method: "DELETE",
        body: JSON.stringify(e.target.parentNode.values),
        signal: this.abortController.signal,
      })
        .then((response) => {
          if (response.ok) {
            this.updateData();
            [...form.elements].forEach((x) => (x.disabled = false));
            this.registrationToReject = null;
          }
        })
        .catch(console.error);
    };

    abortRejection = () => {
      this.registrationToReject = null;
    };

    firstUpdated = () => {
      super.firstUpdated();
      this.updateData();
    };

    downloadExcel = () =>
      zipcelx({
        filename: `${this.data.date} ${this.data.title}`,
        sheet: {
          data: [
            [{ value: `${this.data.title} am ${dayFormatter.format(Date.parse(this.data.date))}`, type: "string" }],
            [],
            [
              { value: "Trainer", type: "string" },
              {
                value: this.data.all_trainers
                  .sort((a, b) => (a.rank > b.rank ? -1 : 1))
                  .map((x) => x.display_name)
                  .join(", "),
                type: "string",
              },
            ],
            [],
            [
              { value: "Name", type: "string" },
              { value: "Vorname", type: "string" },
              { value: "Alter", type: "string" },
              { value: "Graduierung", type: "string" },
              { value: "Verein", type: "string" },
              { value: "anwesend", type: "string" },
            ],
            ...this.data.registrations
              .sort((a, b) => (a.name + a.given_name < b.name + b.given_name ? -1 : 1))
              .map((x) => [
                { value: x.name, type: "string" },
                { value: x.given_name, type: "string" },
                { value: x.age, type: "string" },
                { value: rankNames[x.rank], type: "string" },
                { value: x.dojo?.name, type: "string" },
              ]),
          ],
        },
      });

    render = () => {
      if (!this.data) {
        return null;
      }

      if (
        !state.session.user.roles.includes("admin") &&
        !state.session.user.roles.includes("board_member") &&
        !this.data.all_trainers.map((x) => x.id).includes(state.session.user.id)
      ) {
        return null;
      }

      const attendance_options = this.data.options.filter((x) => x.type === "event_attendance_option");
      const misc_options = this.data.options.filter((x) => x.type === "event_option");
      let registrations = this.data.registrations
        .filter((x) => x.valid)
        .sort((a, b) => a.validated > b.validated)
        .map((x, i) => ({ ...x, position: i + 1 }));
      let waitlist = null;
      if (this.data.capacity) {
        waitlist = registrations.splice(this.data.capacity);
      }

      const optionsConfig = (option) => ({
        render: (x) => (x.options.some((o) => o.id === option.id) ? html`&times;` : null),
        class: "centered option",
        footer: (data) => data.filter((x) => x.options.some((o) => o.id === option.id)).length,
        footerClass: "centered",
      });

      const ageGraph = [
        {
          name: "bis 12 Jahre",
          y: registrations.filter((x) => x.age < 12).length,
        },
        {
          name: "12 - 18 Jahre",
          y: registrations.filter((x) => range(12, 18).includes(x.age)).length,
        },
        {
          name: "18 - 35 Jahre",
          y: registrations.filter((x) => range(18, 35).includes(x.age)).length,
        },
        {
          name: "35 - 50 Jahre",
          y: registrations.filter((x) => range(35, 50).includes(x.age)).length,
        },
        {
          name: "ab 50 Jahre",
          y: registrations.filter((x) => x.age >= 50).length,
        },
      ];
      const sexGraph = [
        {
          name: "keine Angabe",
          color: "lightgray",
          y: registrations.filter((x) => !x.sex).length,
        },
        {
          name: "männlich",
          color: "deepskyblue",
          y: registrations.filter((x) => x.sex === "MALE").length,
        },
        {
          name: "weiblich",
          color: "pink",
          y: registrations.filter((x) => x.sex === "FEMALE").length,
        },
        {
          name: "divers",
          color: "gray",
          y: registrations.filter((x) => x.sex === "INTER").length,
        },
      ];
      const dojoGraph = state.dojos.map((dojo) => ({
        name: dojo.name,
        y: registrations.filter((x) => x.dojo_id === dojo.id).length,
      }));
      const rankGraph = [
        {
          name: "unbekannt",
          color: "lightgray",
          y: registrations.filter((x) => !x.rank).length,
        },
        {
          name: "8. Kyu",
          color: "gold",
          y: registrations.filter((x) => range(ranks.HAKKYU, ranks.NANAKYU).includes(ranks.get(x.rank)?.value)).length,
        },
        {
          name: "7. Kyu",
          color: "orange",
          y: registrations.filter((x) => range(ranks.NANAKYU, ranks.ROKKYU).includes(ranks.get(x.rank)?.value)).length,
        },
        {
          name: "6. Kyu",
          color: "green",
          y: registrations.filter((x) => range(ranks.ROKKYU, ranks.GOKYU).includes(ranks.get(x.rank)?.value)).length,
        },
        {
          name: "5. Kyu",
          color: "blue",
          y: registrations.filter((x) => range(ranks.GOKYU, ranks.YONKYU).includes(ranks.get(x.rank)?.value)).length,
        },
        {
          name: "4. - 1. Kyu",
          color: "saddlebrown",
          y: registrations.filter((x) => range(ranks.YONKYU, ranks.SHODAN).includes(ranks.get(x.rank)?.value)).length,
        },
        {
          name: "Yudansha",
          color: "black",
          y: registrations.filter((x) => ranks.get(x.rank) >= ranks.SHODAN).length,
        },
      ];
      const optionGraph = this.data.options.map((option) => ({
        name: option.name,
        y: registrations.filter((x) => x.options.find((o) => o.id === option.id)).length,
      }));

      return html`<header>
          <h5>
            ${this.data.title} am
            ${dayFormatter.format(Date.parse(this.data.date))}${this.data.location
              ? html` in ${this.data.location.city}`
              : null}
            <img
              src="images/xls.png"
              title="Excel-Datei exportieren"
              class="action"
              style="float: right"
              @click=${this.downloadExcel}
            />
          </h5>
        </header>
        <main>
          <div class="centered">
            <table style="vertical-align: top">
              ${renderTable(this, registrations, {
                columns: [
                  { header: "Pos.", accessor: "position", class: "right" },
                  {
                    header: "Name",
                    accessor: (x) => `${x.name}, ${x.given_name}`,
                    sortable: true,
                    filterable: true,
                  },
                  ...(state.session.user.roles.includes("admin") || state.session.user.roles.includes("board_member")
                    ? [
                        {
                          header: "Adresse",
                          render: (x) => html`${x.street}<br />${x.postal_code} ${x.city}`,
                          class: "nowrap",
                          filterable: true,
                          accessor: (x) => `${x.street} ${x.additional_address} ${x.postal_code} ${x.city}`,
                        },
                        {
                          header: "Telefon",
                          renderTD: (x) => html`<td colspan="2">${x.phone}<br />${x.email}</td>`,
                          sortable: true,
                          filterable: true,
                          accessor: "phone",
                        },
                        {
                          header: "E-Mail",
                          renderTD: (x) => null,
                          sortable: true,
                          filterable: true,
                          accessor: "email",
                        },
                      ]
                    : []),
                  {
                    header: "Alter",
                    accessor: "age",
                    class: "centered",
                    sortable: true,
                    filterable: true,
                  },
                  {
                    header: "Geschlecht",
                    render: (x) => html`<x-sex value="${x.sex}" />`,
                    class: "centered sex",
                    sortable: true,
                    accessor: "sex",
                  },
                  ...(this.data.type !== "meeting"
                    ? [
                        {
                          header: "Graduierung",
                          renderTD: (x) => html`<td colspan="2"><x-rank value="${x.rank}" />${x.dojo?.name}</td>`,
                          sortable: true,
                          accessor: (x) => ranks.get(x.rank),
                          filterable: (x, mask) => rankNames[x.rank]?.toLowerCase().includes(mask.toLowerCase()),
                        },
                        {
                          header: "Verein",
                          renderTD: (x) => null,
                          sortable: true,
                          filterable: true,
                          accessor: (x) => x.dojo?.name,
                        },
                      ]
                    : []),
                  ...(attendance_options.length > 1
                    ? attendance_options.map((option) => ({
                        header: option.name,
                        ...optionsConfig(option),
                      }))
                    : []),
                  ...(misc_options
                    ? misc_options.map((option) => ({
                        header: option.name,
                        ...optionsConfig(option),
                      }))
                    : []),
                  ...(this.data.registration_needs_confirmation
                    ? [
                        {
                          header: "Bestätigung",
                          render: (x) =>
                            html`<span class="action confirmation" @click=${() => this.confirmRegistration(x)}>✅</span
                              ><span class="action" @click=${() => (this.registrationToReject = x)}>🚫</span>`,
                          class: "centered",
                        },
                      ]
                    : []),
                ],
                sort: this.sort,
                filter: this.filter,
              })}
              ${this.data.waitlist
                ? html`<tr>
                      <th
                        class="centered"
                        colspan=${7 +
                        (this.data.type !== "meeting" ? 2 : 0) +
                        (attendance_options.length > 1 ? attendance_options.length : 0) +
                        misc_options.length +
                        this.data.registration_needs_confirmation}
                      >
                        Warteliste
                      </th>
                    </tr>
                    ${renderTable(waitlist, {
                      columns: [
                        { header: "Pos.", accessor: "position", class: "right" },
                        {
                          header: "Name",
                          render: (x) => html`${x.name}, ${x.given_name}`,
                        },
                        ...(state.session.user.roles.includes("admin") ||
                        state.session.user.roles.includes("board_member")
                          ? [
                              {
                                header: "Adresse",
                                render: (x) => html`${x.street}<br />${x.postal_code} ${x.city}`,
                                class: "nowrap",
                              },
                              {
                                header: "Telefon",
                                renderTD: (x) => html`<td colspan="2">${x.phone}<br />${x.email}</td>`,
                              },
                              { header: "E-Mail", renderTD: (x) => null },
                            ]
                          : []),
                        { header: "Alter", accessor: "age" },
                        {
                          header: "Geschlecht",
                          render: (x) => html`<x-sex value="${x.sex}" />`,
                          class: "centered",
                        },
                        ...(this.data.type !== "meeting"
                          ? [
                              {
                                header: "Graduierung",
                                render: (x) => html`<x-rank value="${x.rank}" />`,
                              },
                              { header: "Verein", render: (x) => x.dojo.name },
                            ]
                          : []),
                        ...(attendance_options.length > 1
                          ? attendance_options.map((option) => ({
                              header: option.name,
                              ...optionsConfig(option),
                            }))
                          : []),
                        ...(misc_options
                          ? misc_options.map((option) => ({
                              header: option.name,
                              ...optionsConfig(option),
                            }))
                          : []),
                        ...(this.data.registration_needs_confirmation ? [{ header: "", render: (x) => null }] : []),
                      ],
                    })}`
                : null}
            </table>
            <div class="charts">
              <x-chart
                class="age"
                .options=${{
                  title: { text: "Alter" },
                  chart: { options3d: { enabled: true, alpha: 45, beta: 0 } },
                  plotOptions: {
                    pie: {
                      depth: 50,
                      dataLabels: {
                        format: "{point.name}: {point.y} ({point.percentage:.2f}%)",
                      },
                    },
                  },
                  tooltip: { enabled: false },
                  series: [{ type: "pie", data: ageGraph.filter((x) => x.y !== 0) }],
                }}
              />
              <x-chart
                class="sex"
                .options=${{
                  title: { text: "Geschlecht" },
                  chart: { options3d: { enabled: true, alpha: 45, beta: 0 } },
                  plotOptions: {
                    pie: {
                      depth: 50,
                      dataLabels: {
                        format: "{point.name}: {point.y} ({point.percentage:.2f}%)",
                      },
                    },
                  },
                  tooltip: { enabled: false },
                  series: [
                    {
                      type: "pie",
                      colorKey: "color",
                      data: sexGraph.filter((x) => x.y !== 0),
                    },
                  ],
                }}
              />
              <x-chart
                class="dojo"
                .options=${{
                  title: { text: "Verein" },
                  chart: { options3d: { enabled: true, alpha: 45, beta: 0 } },
                  plotOptions: {
                    pie: {
                      depth: 50,
                      dataLabels: {
                        format: "{point.name}: {point.y} ({point.percentage:.2f}%)",
                      },
                    },
                  },
                  tooltip: { enabled: false },
                  series: [{ type: "pie", data: dojoGraph.filter((x) => x.y !== 0) }],
                }}
              />
              <x-chart
                class="rank"
                .options=${{
                  title: { text: "Graduierungen" },
                  chart: { options3d: { enabled: true, alpha: 45, beta: 0 } },
                  plotOptions: {
                    pie: {
                      depth: 50,
                      dataLabels: {
                        format: "{point.name}: {point.y} ({point.percentage:.2f}%)",
                      },
                    },
                  },
                  tooltip: { enabled: false },
                  series: [
                    {
                      type: "pie",
                      colorKey: "color",
                      data: rankGraph.filter((x) => x.y !== 0),
                    },
                  ],
                }}
              />
              ${this.data.options.length > 1
                ? html`<x-chart
                    class="options"
                    .options=${{
                      title: { text: "Anmelde-Optionen" },
                      xAxis: { type: "category" },
                      yAxis: { title: { text: "" }, min: 0 },
                      plotOptions: {
                        column: {
                          dataLabels: { format: "{point.name}: {point.y}" },
                        },
                      },
                      tooltip: { enabled: false },
                      legend: { enabled: false },
                      series: [
                        {
                          type: "column",
                          data: optionGraph.filter((x) => x.y !== 0),
                          dataLabels: { enabled: true, format: "{point.y}" },
                        },
                      ],
                    }}
                  />`
                : null}
            </div>
          </div>
          <x-djjb-modal ?open=${this.registrationToReject} @closed=${this.abortRejection} sliding centered>
            ${this.registrationToReject &&
            html`<div class="shadow">
              <x-form @submit=${this.rejectRegistration} @cancel=${this.abortRejection}>
                <header>
                  <h5>
                    Anmeldung ablehnen: ${this.registrationToReject.name}, ${this.registrationToReject.given_name}
                  </h5>
                  <span class="close-button" @click=${this.abortRejection}>&times;</span>
                </header>
                <main>
                  <p>
                    Soll die Anmeldung wirklich abgelehnt werden? ${this.registrationToReject.given_name}
                    ${this.registrationToReject.name} erhält eine E-Mail.
                  </p>
                  <div class="field confirmation-dialog">
                    <input type="text" name="reason" placeholder="Begründung (optional, wird in E-Mail angegeben)" />
                  </div>
                </main>
                <footer class="justify-right">
                  <button type="button" name="cancel">Abbrechen</button>
                  <button type="submit">Ablehnen</button>
                </footer>
              </x-form>
            </div>`}
          </x-djjb-modal>
        </main>`;
    };
  }
);
