import "./EventList.js";
import "./Message.js";
import "./Registrations.js";
import "./Profile.js";

import { httpRequest } from "../util.js";

customElements.define(
  "x-main",
  class extends HTMLElement {
    constructor() {
      super();
      window.addEventListener("hashchange", this.onHashChange);
      this.onHashChange();
    }

    onHashChange = () => {
      const route = window.location.hash.substring(1);
      while (this.lastChild) this.lastChild.remove();
      switch (route) {
        case "":
          this.append(document.createElement("x-event-list"));
          return;
        case /^event=/.test(route) && route:
          {
            const events = document.createElement("x-event-list");
            const event_id = route.split("=")[1];
            events.setAttribute("selected", event_id);
            this.append(events);
            return;
            }
        case /^confirm_registration=/.test(route) && route:
          {
            this.append(document.createElement("x-event-list"));
            const [id, token] = route.split("=")[1].split(",");
            httpRequest(`/registrations/${id}?rel=event`, {
              method: "POST",
              body: JSON.stringify({ token: token }),
              credentials: "include",
            })
              .then((response) => response.ok && response.json())
              .then((data) => {
                let message = document.createElement("x-message");
                message.setAttribute("caption", data.event.title);
                message.setAttribute("status", "success");
                message.setAttribute(
                  "message",
                  "Vielen Dank. Die Anmeldung wurde bestätigt. An die angegebene E-Mail-Adresse wurden weitere Informationen geschickt."
                );
                this.append(message);
              })
              .catch((error) => {
                console.error(error);
                let message = document.createElement("x-message");
                message.setAttribute("caption", "Ein Fehler ist aufgetreten!");
                message.setAttribute("status", "error");
                message.setAttribute("message", "Die angegebenen Anmeldedaten konnten nicht bestätigt werden.");
                this.append(message);
              });
          }
          return;
        case /^delete_registration=/.test(route) && route:
          {
            this.append(document.createElement("x-event-list"));
            const [id, token] = route.split("=")[1].split(",");
            httpRequest(`/registrations/${id}?rel=event`, {
              method: "DELETE",
              body: JSON.stringify({ token: token }),
              credentials: "include",
            })
              .then((response) => response.ok && response.json())
              .then((data) => {
                let message = document.createElement("x-message");
                message.setAttribute("caption", data.event.title);
                message.setAttribute("status", "success");
                message.setAttribute(
                  "message",
                  "Die Anmeldung wurde gelöscht und eine entsprechende Bestätigung an die angegebene E-Mail-Adresse geschickt."
                );
                this.append(message);
              })
              .catch((error) => {
                console.error(error);
                let message = document.createElement("x-message");
                message.setAttribute("caption", "Ein Fehler ist aufgetreten!");
                message.setAttribute("status", "error");
                message.setAttribute("message", "Die angegebene Anmeldung konnte nicht gelöscht werden.");
                this.append(message);
              });
          }
          return;
        case /^registrations=/.test(route) && route:
          {
            const registrations = document.createElement("x-registrations");
            registrations.setAttribute("event", route.split("=")[1]);
            this.append(registrations);
          }
          return;
        case "profile":
          this.append(document.createElement("x-profile"));
          return;
      }
      window.location.hash = "";
    };
  }
);
