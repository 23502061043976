import { LitElement, html } from "../lit.js";
import clsx from "../lib/clsx.js";

import "../widgets/NavIcon.js";

import "./UserMenu.js";
import "./LogIn.js";
import "./ChangePassword.js";

customElements.define(
  "x-menu",
  class extends LitElement {
    static properties = {
      open: { type: Boolean },
      showLogin: { type: Boolean, reflect: false },
    };

    constructor() {
      super();
      this.showLogin = false;
    }

    createRenderRoot() {
      return this;
    }

    updated = () => {
      // this.parentElement.parentElement.style.minHeight = `${this.parentElement.scrollHeight}px`;
      this.dispatchEvent(new Event("updated"));
    };

    render = () =>
      html`<div class="${clsx({ open: this.open })}">
          <x-user-menu @login=${() => (this.showLogin = true)} @updated=${this.updated} />
        </div>
        <x-login-dialog ?open=${this.showLogin} @closed=${() => (this.showLogin = false)} />
        <x-change-password-dialog />`;
  }
);
