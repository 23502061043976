// import confetti from "./lib/canvas-confetti.js";
import { MobxLitElement, html } from "./lit.js";

import { state } from "./state.js";
import { uiURL } from "./util.js";

import "./components/Menu.js";
import "./components/Main.js";
import "./components/PrintedPlan.js";

// confetti();

const datetimeFormatter = new Intl.DateTimeFormat("de-DE", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  timeZone: "Europe/Berlin",
});

customElements.define(
  "x-lehrgangsanmeldung",
  class extends MobxLitElement {
    static properties = {
      noIssueHint: { type: Boolean },
      menuOpen: { type: Boolean, reflect: false },
    };
    constructor() {
      super();
      this.noIssueHint = false;
      this.menuOpen = false;
      this.user_id = null;
      let fontDefinition = document.createElement("style");
      fontDefinition.innerText = `@font-face {
        font-family: "Open Sans Condensed";
        font-style: normal;
        font-weight: 700;
        src: url("${uiURL("/fonts/opensanscondensed-bold.ttf")}") format("truetype");
      }
      @font-face {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        src: url("${uiURL("/fonts/roboto-regular.ttf")}") format("truetype");
      }
      @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        src: url("${uiURL("/fonts/roboto-italic.ttf")}") format("truetype");
      }
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        src: url("${uiURL("/fonts/roboto-bold.ttf")}") format("truetype");
      }
      `;
      document.head.append(fontDefinition);
      window.addEventListener("resize", () => this.requestUpdate());
    }

    firstUpdated = () => {
      if (!this.noIssueHint) {
        const issueHint = document.createElement("p");
        issueHint.innerHTML =
          'Probleme bei der Anmeldung? Bitte per E-Mail an <a style="text-decoration: underline" href="mailto:tobias.foehst@djjb.de">tobias.foehst@djjb.de</a> wenden.';
        this.parentElement.insertBefore(issueHint, this);
      }
      this.requestUpdate();
    };

    updated = () => {
      const main = this.querySelector("main");
      const nav = this.querySelector("nav");
      if (main && nav) {
        main.style.height = `calc(100vh - ${nav.scrollHeight}px`;
      }
      const user_id = state.session ? state.session.user_id : null;
      if (this.user_id !== user_id) {
        this.menuOpen = false;
        this.user_id = user_id;
      }
    };

    render = () => html`
      <style>
        :host {
          all: initial;
          font-size: 100%;
        }
      </style>
      <link rel="stylesheet" href="${uiURL("/css/style.css")}" />
      ${[340, 480, 600, 768, 992]
        .filter((x) => this.offsetWidth >= x)
        .map((x) => html`<link rel="stylesheet" href="${uiURL(`/css/min-${x}.css`)}" />`)}
      <div lang="de">
        <nav lang="de">
          <a href="#" class="brand">DJJB Lehrgangsplan</a>
          <div class="age">
            (Stand: ${state.last_update ? datetimeFormatter.format(Date.parse(state.last_update)) : "offline"})
            (<a
              href="https://djjb.foehst.net/DJJB-Lehrgangsplan.pdf"
              style="color: #ff0;"
              target="_new"
              >Download</a>)
          </div>
          <!--a href="#profile">Profile</a>
          <a href="#c">c</a-->
          <x-nav-icon ?open=${this.menuOpen} @click=${() => (this.menuOpen = !this.menuOpen)} />
          <x-menu ?open=${this.menuOpen} @updated=${this.updated} />
        </nav>
        <main lang="de">
          <x-main />
        </main>
      </div>
    `;
  }
);
