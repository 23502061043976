export const uiURL = (path) => {
  if (typeof process.env.UI_URL_PREFIX !== "undefined") {
    return process.env.UI_URL_PREFIX + path;
  }
  // if (typeof process.env.UI_PORT !== "undefined") {
  //   let origin = window.location.origin.replace(
  //     /:\d+$/,
  //     ":" + process.env.UI_PORT
  //   );
  //   return origin + path;
  // }
  return path;
};

export const backendURL = (path) => {
  if (/^https?:\/\//.test(path)) return path;
  if (typeof process.env.BACKEND_URL_PREFIX !== "undefined") {
    return process.env.BACKEND_URL_PREFIX + path;
  }
  if (typeof process.env.BACKEND_PORT !== "undefined") {
    let origin = window.location.origin.replace(/:\d+$/, ":" + process.env.BACKEND_PORT);
    return origin + path;
  }
  return path;
};

export const httpRequest = (url, parameters) =>
  fetch(backendURL(url), {
    credentials: "include",
    headers: { Accept: "application/json" },
    ...parameters,
  });

export const uuid = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );

export const isDefined = (value) => value != null;

export const isBoolean = (value) => Object.prototype.toString.call(value) === "[object Boolean]";

export const isNumber = (value) => !isNaN(+value);

export const isString = (value) => Object.prototype.toString.call(value) === "[object String]";

export const isDate = (value) => Object.prototype.toString.call(value) === "[object Date]" && !isNaN(value.getTime());

export const isObject = (x) => typeof x === "object" && x !== null;

export const deepEquals = (a, b) => {
  if (!isObject(a) || !isObject(b)) return a === b;
  if (a === b) return true;
  if (Object.keys(a).length !== Object.keys(b).length) return false;
  for (const key in a) {
    if (!key in b || !deepEquals(a[key], b[key])) return false;
  }
  for (const key in b) {
    if (!key in a) return false;
  }
  return true;
};

export const unique = (values) => values.filter((x, i) => values.findIndex((y) => deepEquals(x, y)) == i);

export const range = (begin, end) => [...Array(end - begin).keys()].map((x) => begin + x);

export const transformData = (data, transform) => {
  if (isObject(data)) {
    Object.keys(data).map((k) => (data[k] = transformData(data[k], transform)));
    return data;
  }
  if (Array.isArray(data)) {
    return data.map((x) => transformData(x, transform));
  }
  return transform(data);
};

export const trimStrings = (data) => transformData(data, (d) => (typeof d === "string" ? d.trim() : d));

export const replaceEmptyStringsByNull = (data) => transformData(data, (d) => (d === "" ? null : d));

export const parseDateFromString = (value) => {
  let m = value.match(/^(\d+).(\d+).(\d{4})$/);
  if (m) return new Date(m[3], m[2] - 1, m[1]);
  return new Date(Date.parse(value));
};
