import {
  isDefined,
  isBoolean,
  isNumber,
  isString,
  isDate,
  isObject,
  deepEquals,
  unique,
  parseDateFromString,
} from "./util.js";

const resolvePath = (path, data) => (data ? data[path] : null);

class SchemaNode {
  constructor() {
    this._when = [];
    this._test = [];
  }
  required(message) {
    this._required = message || "Fehlt!";
    return this;
  }
  oneOf(candidates, message) {
    this._oneOf = {
      candidates: candidates,
      message: message || "Unerwarteter Wert!",
    };
    return this;
  }
  when(path, condition) {
    this._when.push({ path: path, ...condition });
    return this;
  }
  test(f, message) {
    this._test.push({ test: f, message: message });
    return this;
  }
  validate(data, parent) {
    this._when.filter((x) => resolvePath(x.path, parent) === x.is).forEach((x) => x.then(this));

    const errors = this.applyTests(data);
    if (errors) return errors;

    const error = this._test.find((x) => !x.test(data));
    if (error) return error.message;

    if (this._required && !isDefined(data)) {
      return this._required;
    }
    if (isDefined(data)) {
      if (this._oneOf && !this._oneOf.candidates.some((x) => x === data)) return this._oneOf.message;
    }
  }
}

class BooleanSchema extends SchemaNode {
  true(message) {
    this._true = message || "Muss wahr sein!";
    return this;
  }
  false(message) {
    this._false = message || "Muss falsch sein!";
    return this;
  }
  applyTests(data) {
    if (isDefined(data) && !isBoolean(data)) return "Kein Boolean!";
    if (this._true && data !== true) return this._true;
    if (this._false && data !== false) return this._false;
  }
}

class NumberSchema extends SchemaNode {
  min(value, message) {
    this._min = { value: value, message: message || "Zu klein!" };
    return this;
  }
  max(value, message) {
    this._max = { value: value, message: message || "Zu groß!" };
    return this;
  }
  integer(message) {
    this._integer = message || "Nur ganze Zahlen erlaubt!";
    return this;
  }
  applyTests(data) {
    if (isDefined(data)) {
      data = +data;
      if (!isNumber(data)) return "Keine Zahl!";
      if (this._integer && !Number.isInteger(data)) return this._integer;
      if (this._min && data < this._min.value) return this._min.message;
      if (this._max && data > this._max.value) return this._max.message;
    }
  }
}

const convertToDate = (value) => {
  let date = value;
  if (isNumber(date)) date = new Date(value);
  if (isString(date)) date = parseDateFromString(date);
  if (!isDate(date)) console.error(`"${value}" is not a valid date`);
  return date;
};

class DateSchema extends SchemaNode {
  min(value, message) {
    this._min = { value: convertToDate(value), message: message || "Zu früh!" };
    return this;
  }
  max(value, message) {
    this._max = { value: convertToDate(value), message: message || "Zu spät!" };
    return this;
  }
  applyTests(data) {
    if (isDefined(data)) {
      data = convertToDate(data);
      if (!isDate(data)) return "Kein Datum!";
      if (this._min && data < this._min.value) return this._min.message;
      if (this._max && data > this._max.value) return this._max.message;
    }
  }
}

class StringSchema extends SchemaNode {
  trim() {
    this._trim = true;
    return this;
  }
  min(length, message) {
    this._min = { length: length, message: message || "Zu kurz!" };
    return this;
  }
  email(message) {
    this._email = message || "Keine gültige E-Mail-Adresse";
    return this;
  }
  regex(regex, message) {
    this._regex = { regex: regex, message: message || "Unerwartetes Format!" };
    return this;
  }
  applyTests(data) {
    if (isDefined(data)) {
      if (!isString(data)) return "Kein String!";
      if (this._trim) data = data.trim();
      if (this._min && data.length < this._min.length) return this._min.message;
      if (this._email && !/^\S+@\S+\.\S+/.test(data)) return this._email;
      if (this._regex && !this._regex.regex.test(data)) return this._regex.message;
      if (this._required && !data) return this._required;
    }
  }
}

class ArraySchema extends SchemaNode {
  constructor(element) {
    super();
    this._element = element;
  }
  min(value, message) {
    this._min = { value: value, message: message || "Zu wenig Elemente!" };
    return this;
  }
  max(value, message) {
    this._max = { value: value, message: message || "Zu viele Elemente!" };
    return this;
  }
  unique(message) {
    this._unique = message || "Enthält doppelte Einträge!";
    return this;
  }
  applyTests(data) {
    if (isDefined(data)) {
      if (!Array.isArray(data)) return "Kein Array!";
      if (this._min && data.length < this._min.value) return this._min.message;
      if (this._max && data.length > this._max.value) return this._max.message;
      if (this._unique && !deepEquals(data, unique(data))) return this._unique;
      const errors = data
        .map((x, i) => ({ index: i, result: this._element.validate(x, data) }))
        .filter((x) => x.result)
        .reduce((p, c) => ({ ...p, [c.index]: c.result }), {});
      if (Object.keys(errors).length) return errors;
    }
  }
}

class StructSchema extends SchemaNode {
  constructor(children) {
    super();
    this._children = children || {};
  }
  noUnknown(message) {
    this._noUnknown = message || "Unerwartetes Feld!";
    return this;
  }
  applyTests(data) {
    if (isDefined(data)) {
      // if (Object.prototype.toString.call(data) !== "[object Object]")
      //   return "Kein Struct!";
      if (!isObject(data)) return "Kein Struct!";
      if (this._noUnknown && Object.keys(data).some((x) => !x in this._children)) return this._noUnknown;
      const errors = Object.entries(this._children)
        .map(([k, v]) => [k, v.validate(data[k], data)])
        .filter(([_, v]) => v)
        .reduce((p, c) => ({ ...p, [c[0]]: c[1] }), {});
      if (Object.keys(errors).length) return errors;
    }
  }
}

export default {
  Boolean: () => new BooleanSchema(),
  Number: () => new NumberSchema(),
  Date: () => new DateSchema(),
  String: () => new StringSchema(),
  Array: (element) => new ArraySchema(element),
  Struct: (children) => new StructSchema(children),
};
