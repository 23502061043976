import Enum from "../lib/enum.js";

import { LitElement, html, css } from "../lit.js";

import style from "./Rank.css";

export const ranks = new Enum({
  KUKYU: 1,
  NANAMON: 2,
  HAKKYU: 3,
  GOMON: 4,
  NANAKYU: 5,
  SANMON: 6,
  ROKKYU: 7,
  ICHIMON: 8,
  GOKYU: 9,
  YONKYU: 10,
  SANKYU: 11,
  NIKYU: 12,
  IKKYU: 13,
  SHODAN: 14,
  NIDAN: 15,
  SANDAN: 16,
  YONDAN: 17,
  GODAN: 18,
  ROKUDAN: 19,
  NANADAN: 20,
  HACHIDAN: 21,
  KUDAN: 22,
  JUDAN: 23,
});

export const rankNames = {
  KUKYU: "9. Kyu / 8. Mon",
  NANAMON: "7. Mon",
  HAKKYU: "8. Kyu / 6. Mon",
  GOMON: "5. Mon",
  NANAKYU: "7. Kyu / 4. Mon",
  SANMON: "3. Mon",
  ROKKYU: "6. Kyu / 2. Mon",
  ICHIMON: "1. Mon",
  GOKYU: "5. Kyu",
  YONKYU: "4. Kyu",
  SANKYU: "3. Kyu",
  NIKYU: "2. Kyu",
  IKKYU: "1. Kyu",
  SHODAN: "1. Dan",
  NIDAN: "2. Dan",
  SANDAN: "3. Dan",
  YONDAN: "4. Dan",
  GODAN: "5. Dan",
  ROKUDAN: "6. Dan",
  NANADAN: "7. Dan",
  HACHIDAN: "8. Dan",
  KUDAN: "9. Dan",
  JUDAN: "10. Dan",
};

customElements.define(
  "x-rank",
  class extends LitElement {
    static properties = { value: { type: String } };

    static styles = css([style]);

    render = () => html`<div class="${this.value}">${rankNames[this.value]}</div>`;
  }
);
