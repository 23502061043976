import { LitElement, html, css } from "../lit.js";
import clsx from "../lib/clsx.js";

import style from "./NavIcon.css";

customElements.define(
  "x-nav-icon",
  class extends LitElement {
    static properties = {
      open: { type: Boolean },
    };

    static styles = css([style]);

    constructor() {
      super();
      this.open = false;
    }

    render = () => html`<div class="${clsx({ open: this.open })}" />`;
  }
);
