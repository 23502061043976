import { LitElement, html, css } from "../lit.js";
import { uuid } from "../util.js";

import "./Select.js";

import style from "./Input.css";

class CheckableInput extends LitElement {
  static properties = {
    name: { type: String },
    value: { type: Object },
    label: { type: String },
    checked: { type: Boolean },
    disabled: { type: Boolean },
  };

  constructor() {
    super();
    this._id = uuid();
  }

  createRenderRoot() {
    return this;
  }

  render = () => html`<style>
      ${css([style])}
    </style>
    <input
      type="${this._type}"
      id="${this._id}"
      name="${this.name}"
      value="${JSON.stringify(this.value)}"
      ?disabled=${this.disabled}
    /><label for="${this._id}">${this.label}</label>`;
}

customElements.define(
  "x-radio",
  class extends CheckableInput {
    _type = "radio";
  }
);
customElements.define(
  "x-checkbox",
  class extends CheckableInput {
    _type = "checkbox";
  }
);
