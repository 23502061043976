import clsx from "../lib/clsx.js";

import { LitElement, html } from "../lit.js";
import { httpRequest } from "../util.js";

// import "../widgets/Collapsible.js";

// import "./Event.js";

import { state } from "../state.js";

const monthFormatter = new Intl.DateTimeFormat("de-DE", { month: "long" });

const dayFormatter = new Intl.DateTimeFormat("de-DE", {
  weekday: "short",
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  timeZone: "Europe/Berlin",
});

const datetimeFormatter = new Intl.DateTimeFormat("de-DE", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  timeZone: "Europe/Berlin",
});

customElements.define(
  "x-content",
  class extends LitElement {
    static properties = {
      year: { type: String },
      region: { type: String },
      watermark: { type: String },
    };
    createRenderRoot() {
      return this;
    }
    render = () => {
      let events_on_page = 0;

      const pages = [{}];
      Object.entries(Object.values(this.data)[0]).forEach(([k, v]) => {
        const events = v.filter((x) => this.organizations.includes(x.organizer?.name));
        if (events.length == 0) return;
        events_on_page += events.length + 1;
        const page_break = events_on_page > 9;
        if (page_break) {
          events_on_page = events.length + 1;
          pages.push({});
        }
        pages[pages.length - 1][k] = events;
      });
      return pages.map(
        (p) => html`<section class="${this.watermark}">
          <header>
            <img src="images/djjb_logo.png" />
            <h1>Landeslehrgangsplan ${this.year}</h1>
            <h2>(${this.region})</h2>
            <div>(Stand: ${datetimeFormatter.format(Date.parse(state.last_update))}&nbsp;Uhr)</div>
          </header>
          ${Object.entries(p).map(
            ([month, events]) => html`<h3>${monthFormatter.format(Date.parse([this.year, month, 1].join("-")))}:</h3>
              ${events.map(
                (x) => html`<div class="event">
                  <div class="date">${dayFormatter.format(Date.parse(x.date))}</div>
                  <div class="${clsx("title", { "valid-as-mandatory": x.valid_as_mandatory })}">${x.title}</div>
                  <div class="trainers">
                    ${x.all_trainers?.length
                      ? html`(Lehrer: ${x.all_trainers?.map((x) => x.display_name).join(", ")})`
                      : html``}
                  </div>
                  <div class="location">${x.location?.name}</div>
                  <div class="description">${x.description}</div>
                  <div class="end-of-registration">
                    ${x.end_of_registration
                      ? `Anmeldeschluss: ${dayFormatter.format(Date.parse(x.end_of_registration))}`
                      : null}
                  </div>
                </div>`
              )}`
          )}
          <footer>
            <div>
              ${this.notes}Pflichtlehrgänge des DJJB sind mit diesem Symbol &bdquo;⊙&ldquo; gekennzeichnet und werden
              als solche im Pass eingetragen.<br />
              <span
                >Achtung: LG-Teilnahme nur nach vorheriger Online-Anmeldung über
                www.djjb.de/Verband/lehrgaenge.html</span
              >
            </div>
          </footer>
        </section>`
      );
    };
  }
);

customElements.define(
  "x-print-plan",
  class extends LitElement {
    constructor() {
      super();
      this.year = new Date().getFullYear();
      this.updateEventList();
      // window.setInterval(this.updateEventList, 10000);
    }

    updateEventList = () =>
      httpRequest(
        `/events?rel=time_slots;rel=organizer;rel=location;rel=trainers;rel=options;group_by=year;group_by=month;begin=${this.year}-01-01;end=${this.year}-12-31`
      )
        .then((response) => response.ok && response.json())
        .then((data) => {
          this.data = data;
          this.requestUpdate();
        })
        .catch(console.log);

    createRenderRoot() {
      return this;
    }

    // ["DJJB - LV Niedersachsen", "DJJB - LV Rheinland-Pfalz", "DJJB - LV Baden-Württemberg"]

    render = () => {
      if (this.data) {
        let events_on_page = 0;

        const pages = [{}];
        Object.entries(Object.values(this.data)[0]).forEach(([k, v]) => {
          const events = v.filter((x) =>
            [
              "Korporation Internationaler Danträger",
              "Deutscher Jiu Jitsu Bund",
              "DJJB - LV Nordrhein-Westfalen",
            ].includes(x.organizer?.name)
          );
          if (events.length == 0) return;
          events_on_page += events.length + 1;
          const page_break = events_on_page > 9;
          if (page_break) {
            events_on_page = events.length + 1;
            pages.push({});
          }
          pages[pages.length - 1][k] = events;
        });

        return html`<style>
            @page {
              size: A4 landscape;
            }
            main {
              font-family: Roboto, sans-serif;
              list-style: none;
            }
            header {
              display: grid;
              grid:
                "logo head update"
                "logo region update";
              grid-template-columns: 20% 60% 20%;
            }
            header img {
              grid-area: logo;
              height: 100%;
            }
            header h1 {
              grid-area: head;
              font-size: 4em;
              font-style: italic;
              border-bottom: 0.1em solid black;
              justify-self: center;
              margin: 0;
            }
            header h2 {
              grid-area: region;
              font-size: 2em;
              justify-self: center;
              margin: 0;
            }
            header div {
              grid-area: update;
              font-weight: bold;
              font-size: 1.2em;
              position: relative;
              top: calc(100% - 1.2em);
              right: 0;
              white-space: nowrap;
            }
            footer {
              border-top: 1px solid black;
              position: absolute;
              bottom: 0;
              width: 100%;
              display: grid;
            }
            footer div {
              justify-self: center;
            }
            footer div span {
              color: #f00;
            }
            section {
              width: 100%;
              height: 100vh;
              position: relative;
              page-break-after: always;
            }
            section::after {
              background: url("images/djjb-lvnw.jpg");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              opacity: 0.05;
              z-index: -1;
              content: "";
              margin: 3cm;
            }
            section.lvbw::after {
              background-image: url("images/djjb-lvbw.jpg");
            }
            section.lvni::after {
              background-image: url("images/djjb-lvni.jpg");
            }
            section.lvrp::after {
              background-image: url("images/djjb-lvrp.jpg");
            }
            section > h3 {
              color: #00f;
              font-size: 1.6em;
              font-style: italic;
              letter-spacing: 0.25em;
              margin-bottom: 0.25em;
              margin-top: 0.5em;
            }
            section > div {
              display: grid;
              grid:
                "date title trainers"
                "location description ."
                ". end-of-registration .";
              grid-template-columns: 25% 50% 25%;
              padding-bottom: 0.125em;
              width: 100%;
            }
            section > div:last-of-type {
              padding-bottom: revert;
            }
            .date {
              grid-area: date;
              font-weight: bold;
            }
            .title {
              grid-area: title;
              font-weight: bold;
              position: relative;
            }
            .valid-as-mandatory::before {
              content: "⊙";
              left: -1.5em;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
            .trainers {
              grid-area: trainers;
            }
            .location {
              grid-area: location;
            }
            .description {
              grid-area: description;
            }
            .end-of-registration {
              grid-area: end-of-registration;
            }
          </style>
          <main>
            <x-content
              .data=${this.data}
              year=${this.year}
              region="Landesverband Nordrhein-Westfalen"
              watermark="lvnw"
              .organizations=${[
                "United Nations of Ju Jitsu",
                "Korporation Internationaler Danträger",
                "Deutscher Jiu Jitsu Bund",
                "DJJB - LV Nordrhein-Westfalen",
              ]}
              .notes=${html`<div>
                Die <b>Samstags-Lehrgänge</b> beginnen um <b>16:00 Uhr</b>, die <b>Sonntags-Lehrgänge</b> und
                Dan-Prüfungen beginnen um <b>10:00 Uhr</b>.<br />
                <b>Änderungen vorbehalten!</b> Bei allen Lehrgängen des <b>DJJB - LVNW e.V.</b> ist die Haftung in jeder
                Form ausgeschlossen!<br />
                <b
                  >Für jeden Lehrgangstag werden vom DJJB - LVNW e.V. von jedem DJJB-Mitglied €3,00 Lehrgangsgebühr vor
                  Ort erhoben!</b
                >
              </div>`}
            />
            <x-content
              .data=${this.data}
              year=${this.year}
              region="Landesverband Rheinland-Pfalz"
              watermark="lvrp"
              .organizations=${[
                "United Nations of Ju Jitsu",
                "Korporation Internationaler Danträger",
                "Deutscher Jiu Jitsu Bund",
                "DJJB - LV Rheinland-Pfalz",
              ]}
              .notes=${html`<div>
                Die Uhrzeiten und Lehrgangsgebühren können bei der Anmeldung im Web-Portal eingesehen werden.<br />
                <b>Änderungen vorbehalten!</b> Bei allen Lehrgängen des <b>DJJB- LVRP e.V.</b> ist die Haftung in jeder
                Form ausgeschlossen!
              </div>`}
            />
            <x-content
              .data=${this.data}
              year=${this.year}
              region="Landesvertretung Baden-Württemberg"
              watermark="lvbw"
              .organizations=${[
                "United Nations of Ju Jitsu",
                "Korporation Internationaler Danträger",
                "Deutscher Jiu Jitsu Bund",
                "DJJB - LV Baden-Württemberg",
              ]}
              .notes=${html`<div>
                Die Uhrzeiten und Lehrgangsgebühren können bei der Anmeldung im Web-Portal eingesehen werden.<br />
                <b>Änderungen vorbehalten!</b> Bei allen Lehrgängen ist die Haftung in jeder Form ausgeschlossen!
              </div>`}
            />
            <x-content
              .data=${this.data}
              year=${this.year}
              region="Landesvertretung Niedersachsen"
              watermark="lvni"
              .organizations=${[
                "United Nations of Ju Jitsu",
                "Korporation Internationaler Danträger",
                "Deutscher Jiu Jitsu Bund",
                "DJJB - LV Niedersachsen",
              ]}
              .notes=${html`<div>
                Die <b>Samstags-Lehrgänge</b> beginnen um <b>16:00 Uhr</b>, die <b>Sonntags-Lehrgänge</b> und
                Dan-Prüfungen beginnen um <b>10:00 Uhr</b>.<br />
                <b>Änderungen vorbehalten!</b> Bei allen Lehrgängen ist die Haftung in jeder Form ausgeschlossen!<br />
                <b>Für jeden Lehrgangstag werden von jedem DJJB-Mitglied €3,00 Lehrgangsgebühr vor Ort erhoben!</b>
              </div>`}
            />
          </main>`;
      }
    };
  }
);
