import clsx from "../lib/clsx.js";

import { LitElement, html } from "../lit.js";
import { httpRequest } from "../util.js";

import "../widgets/Collapsible.js";

import "./Event.js";

const monthFormatter = new Intl.DateTimeFormat("de-DE", { month: "long" });

customElements.define(
  "x-event-list-month",
  class extends LitElement {
    static properties = {
      selected: { type: Number },
    };
    createRenderRoot() {
      return this;
    }
    render = () => html`<header>
        <h5>${monthFormatter.format(Date.parse([2000, this.month, 1].join("/")))}</h5>
      </header>
      <ol>
        ${this.data
          // .filter((x) => x.id === 63)
          .map(
            (event) =>
              html`<li class="${clsx({ canceled: [92, 93].includes(event.id) })}"><x-event .showDetails=${event.id === this.selected} .data=${event} /></li>`
          )}
      </ol>`;
  }
);

customElements.define(
  "x-event-list-year",
  class extends LitElement {
    static properties = {
      collapsed: { type: Boolean },
      year: { type: Number },
      selected: { type: Number },
    };
    firstUpdated = () => {
      const date = new Date();
      this.collapsed = date.getFullYear() != this.year;
      if (date.getFullYear() == this.year) {
        // this.scrollIntoView();
      }
    };

    toggle = (e) => {
      this.collapsed = !this.collapsed;
      // this.updateView();
      e.stopPropagation();
    };

    createRenderRoot() {
      return this;
    }

    render = () => html`<header @click=${this.toggle}>
        <h5>${this.year}</h5>
      </header>
      <!--x-collapsible ?collapsed=${this.collapsed} speed="3"-->
      <ol>
        ${Object.keys(this.data)
          // .filter((x) => x == 11)
          .map(
            (month) =>
              html`<li>
                <x-event-list-month selected=${this.selected} .month=${month} .data=${this.data[month]} />
              </li>`
          )}
      </ol>
      <!--/x-collapsible-->`;
  }
);

customElements.define(
  "x-event-list",
  class extends LitElement {
    static properties = {
      selected: { type: Number },
    };

    constructor() {
      super();
      this.updateEventList();
      // window.setInterval(this.updateEventList, 10000);
    }

    updateEventList = () =>
      httpRequest(
        `/events?rel=time_slots;rel=organizer;rel=location;rel=trainers;rel=options;group_by=year;group_by=month;begin=${new Date()
          .toISOString()
          .substr(0, 8)}01`
      )
        .then((response) => response.ok && response.json())
        .then((data) => {
          this.data = data;
          this.requestUpdate();
        })
        .catch(console.log);

    createRenderRoot() {
      return this;
    }

    render = () => {
      if (this.data) {
        const years = Object.keys(this.data);
        return years.length
          ? html`<ol>
              ${years
                // .filter((x) => x == 2020)
                .map(
                  (year) =>
                    html`<li>
                      <x-event-list-year selected=${this.selected} .year=${year} .data=${this.data[year]} />
                    </li>`
                )}
            </ol>`
          : html`<h2 class="centered">Zur Zeit bieten wir keine Lehrgänge an.</h2>`;
      }
    };
  }
);
