import { MobxLitElement, html } from "../lit.js";
import { httpRequest } from "../util.js";
import { state } from "../state.js";
import Schema from "../validation.js";

customElements.define(
  "x-change-password-dialog",
  class extends MobxLitElement {
    abortController = new AbortController();

    changePassword = (e) => {
      e.preventDefault();
      const form = e.target;
      const values = form.parentNode.values;

      [...form.elements].forEach((x) => (x.disabled = true));
      httpRequest("/users/" + state.session.user.id, {
        method: "PATCH",
        body: JSON.stringify(values),
        signal: this.abortController.signal,
      })
        .then(async (response) => {
          if (response.ok) {
            state.checkAuth();
          } else {
            const error = await response.json();
            this.errorMessage = error.message;
            form.password.value = "";
            form.password_confirmation.value = "";
            this.requestUpdate();
          }
          [...form.elements].forEach((x) => (x.disabled = false));
        })
        .catch(console.log);
    };

    createRenderRoot() {
      return this;
    }

    render = () => html`<x-modal
      ?open=${state.session && state.session.user.must_change_password}
      @close=${(e) => e.preventDefault()}
      centered
      sliding
      keepOpen
    >
      <div class="auth-dialog">
        <header><h5>Passwort ändern!</h5></header>
        <main>
          <p class="error-message">Das Passwort muss geändert werden, bevor das Konto weiter genutzt werden kann.</p>
          <x-form
            .validate=${(values) =>
              Schema.Struct({
                password: Schema.String()
                  .min(8, "Das Passwort muss aus mindestens acht Zeichen bestehen!")
                  .required("Passwort fehlt!"),
                password_confirmation: Schema.String().required("Das Passwort muss zur Sicherheit wiederholt werden!"),
              })
                .test((data) => data.password === data.password_confirmation, {
                  password_confirmation: "Die Passwörter stimmen nicht überein!",
                })
                .validate(values)}
            @submit=${this.changePassword}
          >
            <div class="form-field">
              <input type="password" name="password" placeholder="Neues Passwort" />
            </div>
            <div class="form-field">
              <input type="password" name="password_confirmation" placeholder="Neues Passwort wiederholen" />
            </div>
            <x-error-messages />
            <button type="submit">Password ändern</button>
          </x-form>
        </main>
      </div>
    </x-modal>`;
  }
);
