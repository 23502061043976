import { LitElement, html } from "../lit.js";
import { httpRequest } from "../util.js";
import { state } from "../state.js";

import "./DJJBModal.js";

customElements.define(
  "x-login-dialog",
  class extends LitElement {
    static properties = {
      open: { type: Boolean },
    };

    constructor() {
      super();
      this.open = false;
      this.errorMessage = null;
    }

    abortController = new AbortController();

    logIn = (e) => {
      e.preventDefault();
      const form = e.target;
      [...form.elements].forEach((x) => (x.disabled = true));
      httpRequest("/auth", {
        method: "POST",
        body: JSON.stringify({
          username: form.username.value,
          password: form.password.value,
        }),
        signal: this.abortController.signal,
      })
        .then((response) => {
          if (response.ok) {
            state.checkAuth();
            this.open = false;
          } else {
            this.errorMessage = "Anmeldung fehlgeschlagen!";
            form.password.value = "";
            this.requestUpdate();
          }
          [...form.elements].forEach((x) => (x.disabled = false));
        })
        .catch(console.log);
    };

    createRenderRoot() {
      return this;
    }

    render = () => html` <x-djjb-modal
      ?open=${this.open}
      @closed=${() => {
        this.open = false;
      }}
      sliding
    >
      <div class="auth-dialog">
        <header><h5>Anmelden</h5></header>
        <main>
          <x-form @submit=${this.logIn}>
            <div class="form-field">
              <input type="text" inputmode="email" name="username" placeholder="E-Mail-Adresse" />
            </div>
            <div class="form-field">
              <input type="password" name="password" placeholder="Passwort" />
            </div>
            <!--div class="form-field">
                  <x-checkbox
                    class="dark"
                    name="remember_me"
                    label="Auf diesem Gerät angemeldet bleiben"
                  />
                </div-->
            <button type="submit">Anmelden</button>
            <!--p>
                  <a href="#" @click=${this.resetPassword}
                    >Passwort vergessen?</a
                  >
                </p-->
          </x-form>
          ${this.errorMessage ? html`<p class="error-message">${this.errorMessage}</p>` : null}
        </main>
      </div>
    </x-djjb-modal>`;
  }
);
