import { LitElement, html } from "../lit.js";

customElements.define(
  "x-message",
  class extends LitElement {
    static properties = {
      caption: { type: String },
      status: { String },
      message: { type: String },
    };
    createRenderRoot() {
      return this;
    }
    render = () =>
      html`<x-modal open centered sliding>
        <header><h5>${this.getAttribute("caption")}</h5></header>
        <main>
          <div class="${this.getAttribute("status")}-message">${this.getAttribute("message")}</div>
        </main>
      </x-modal>`;
  }
);
