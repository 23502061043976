import { MobxLitElement, html } from "../lit.js";
import { state } from "../state.js";

customElements.define(
  "x-user-menu",
  class extends MobxLitElement {
    createRenderRoot() {
      return this;
    }

    updated = () => this.dispatchEvent(new Event("updated"));

    render = () =>
      state.session
        ? html`<ul>
          <li>${state.session.user.display_name}
            <ul>
              <li @click=${state.logOut}>Ausloggen</a></li>
            </ul>
          </li>
        </ul>`
        : html`<ul>
      <li @click=${() => this.dispatchEvent(new Event("login"))}>Einloggen</a></li>
    </ul>`;
  }
);
