import { LitElement, html, css } from "../lit.js";

import style from "./Collapsible.css";

customElements.define(
  "x-collapsible",
  class extends LitElement {
    static properties = {
      collapsed: { type: Boolean },
      speed: { type: Number },
    };

    static styles = css([style]);

    constructor() {
      super();
      this.initialized = false;
      this.collapsed = false;
      this.speed = 1;
    }

    updated = (changedProperties) => {
      if (!this.initialized) {
        if (this.collapsed) this.shadowRoot.firstElementChild.style.height = "0px";
        this.initialized = true;
        return;
      }

      if (!changedProperties.has("collapsed")) return;

      const height = [...this.children].reduce((p, c) => p + c.offsetHeight, 0);
      const transition = `height ${height / this.speed}ms ease-out`;

      const element = this.shadowRoot.firstElementChild;
      if (this.collapsed) {
        element.style.transition = "";
        requestAnimationFrame(() => {
          element.style.height = `${height}px`;
          element.style.transition = transition;
          requestAnimationFrame(() => (element.style.height = "0px"));
        });
      } else {
        element.style.transition = transition;
        element.style.height = `${height}px`;
        const afterTransition = () => {
          element.removeEventListener("transitionend", afterTransition);
          element.style.height = null;
        };
        element.addEventListener("transitionend", afterTransition);
      }
    };

    render = () => html`<div><slot /></div>`;
  }
);
