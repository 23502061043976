import { deepEquals, isString } from "../util.js";

customElements.define(
  "x-form",
  class extends HTMLElement {
    constructor() {
      super();
      if (!this.values) this.values = {};
      this.errors = {};
    }

    connectedCallback() {
      window.setTimeout(() => this.initialize(), 0);
    }
    initialize() {
      const form = document.createElement("form");
      // [...this.attributes].forEach((x) => form.setAttribute(x.name, x.value));
      form.setAttribute("autocomplete", "new-password");
      form.noValidate = true;
      form.autocomplete = "new-password";
      form.addEventListener("submit", this.submit);
      form.addEventListener("reset", this.reset);
      form.append(...this.children);
      while (this.lastChild) this.lastChild.remove();
      this.append(form);
      if (process.env.NODE_ENV === "development") {
        this.debug = document.createElement("pre");
        this.debug.style.background = "cyan";
        this.debug.style.border = "1px solid black";
        this.append(this.debug);
      }
      [...form.elements]
        .filter((x) => x.tagName === "INPUT")
        .forEach((x) => {
          switch (x.type) {
            case "radio":
              if (deepEquals(this.values[x.name], this.parseJSONOrRaw(x.value))) x.defaultChecked = true;
              break;
            case "checkbox":
              if (
                this.values[x.name] === true ||
                (this.values[x.name] || []).some((option) => deepEquals(option, this.parseJSONOrRaw(x.value)))
              ) {
                x.defaultChecked = true;
              }
              break;
            default:
              if (this.values[x.name]) x.defaultValue = this.values[x.name];
          }
          x.dispatchEvent(new Event("change", { bubbles: true }));
          x.addEventListener("change", this.change);
          x.addEventListener("input", this.input);
        });
      [...form.elements]
        .filter((x) => x.type === "button" && x.name === "cancel")
        .forEach((x) => {
          x.addEventListener("click", this.cancel);
        });
      const errorMessages = form.querySelector("x-error-messages");
      if (errorMessages) {
        this.errorMessages = document.createElement("div");
        errorMessages.replaceWith(this.errorMessages);
      }
      this.updateDebugOutput();
    }

    change = (e) => {
      if (e.target.type === "checkbox") {
        const fields = [...e.target.form.elements].filter((x) => x.name === e.target.name);
        if (Array.isArray(this.values[e.target.name]) || fields.length > 1) {
          this.values[e.target.name] = fields.filter((x) => x.checked).map((x) => this.parseJSONOrRaw(x.value));
        } else {
          this.values[e.target.name] = !!fields.filter((x) => x.checked).length;
        }
      } else {
        this.values[e.target.name] = this.parseJSONOrRaw(e.target.value);
      }
      this.updateDebugOutput();
    };

    input = (e) => {
      e.target.classList.remove("invalid", "valid");
      (e.target.closest(".field") || e.target).classList.remove("invalid", "valid");
      if (this.errors) delete this.errors[e.target.name];
      this.updateErrors(this.errors);
    };

    reset = (e) => {
      [...e.target.elements].forEach((x) => {
        x.classList.remove("invalid", "valid");
        (x.closest(".field") || x).classList.remove("invalid", "valid");
        window.setTimeout(() => x.dispatchEvent(new Event("change", { bubbles: true })), 100);
      });
      this.updateErrors({});
    };

    submit = (e) => {
      if (this.validate) this.updateErrors(this.validate(this.values));
      this.updateDebugOutput();
      if (Object.keys(this.errors).length) {
        e.preventDefault();
        e.stopPropagation();

        [...e.target.elements].forEach((x) => {
          const cls = this.errors[x.name] ? "invalid" : "valid";
          x.classList.add(cls);
          (x.closest(".field") || x).classList.add(cls);
        });
      }
    };

    cancel = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.dispatchEvent(new Event("cancel"));
    };

    parseJSONOrRaw = (value) => {
      try {
        return JSON.parse(value);
      } catch (e) {
        if (e instanceof SyntaxError) {
          return value;
        }
      }
    };

    updateErrors = (errors) => {
      this.errors = errors || {};
      if (!Object.keys(this.errors).length) {
        if (this.errorMessages) {
          this.errorMessages.classList.remove("error-message");
          this.errorMessages.innerHTML = "";
        }
        return;
      }
      if (!this.errorMessages) return;
      this.errorMessages.classList.add("error-message");
      this.errorMessages.innerHTML = Object.entries(this.errors).reduce(
        (p, c) => p + `<div>${isString(c[1]) ? c[1] : JSON.stringify(c[1])}</div>`,
        ""
      );
    };

    updateDebugOutput = () => {
      if (this.debug) {
        this.debug.innerHTML = JSON.stringify({ values: this.values, errors: this.errors }, null, 2);
      }
    };
  }
);
