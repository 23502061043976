import { LitElement, html, css } from "../lit.js";

customElements.define(
  "x-djjb-modal",
  class extends LitElement {
    static properties = {
      open: { type: Boolean, reflect: true },
      sliding: { type: Boolean },
      centered: { type: Boolean },
      keepOpen: { type: Boolean },
    };

    constructor() {
      super();
      // this.open = false;
      this.sliding = false;
      this.centered = false;
      this.keepOpen = false;
      this.old_overflow = null;
    }

    updated = (changedProperties) => {
      if (changedProperties.has("open")) {
        if (this.open) {
          const headerShadow = document.body.querySelector(".header-shadow");
          if (headerShadow) {
            const header = document.body.querySelector("header");
            const clearFix = document.body.querySelector(".clearfix");
            this.originalHeaderPosition = header.style.position;
            this.originalHeaderZIndex = header.style.zIndex;
            this.originalHeaderShadowZIndex = headerShadow.style.zIndex;
            this.originalClearFixZIndex = clearFix.style.zIndex;
            header.style.position = "relative";
            header.style.zIndex = 2;
            headerShadow.style.zIndex = 2;
            clearFix.style.zIndex = 2;
          }
        } else {
          const headerShadow = document.body.querySelector(".header-shadow");
          if (headerShadow) {
            const header = document.body.querySelector("header");
            const clearFix = document.body.querySelector(".clearfix");
            header.style.position = this.originalHeaderPosition;
            header.style.zIndex = this.originalHeaderZIndex;
            headerShadow.style.zIndex = this.originalHeaderZIndex;
            clearFix.style.zIndex = this.originalClearFixZIndex;
          }
          this.dispatchEvent(new Event("closed", { bubbles: true }));
        }
      }
    };

    render = () =>
      html`<x-modal
        ?open=${this.open}
        ?sliding=${this.sliding}
        ?centered=${this.centered}
        ?keepOpen=${this.keepOpen}
        @closed=${() => (this.open = false)}
      >
        <slot
      /></x-modal>`;
  }
);
